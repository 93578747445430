import React, { useContext, useEffect } from "react";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import { OrderformContext } from "../../contexts/orderform.provider";
import CheckOut from "../../services/CheckOut.service";
import helpers from "../../../../utils/helpers";
import "./without-stock.styles.scss";

const WithoutStock = (props: any) => {
  const {
    orderform,
    orderform: {
      exceptions,
      paymentData,
      totalizerData
    },
  } = useContext(OrderformContext);

  const {
    flagWindow,
    isOpen,
    isOpenWin,
    isPay,
    setIsOpen,
    setIsOpenWin
  } = props;

  let withoutStockException =
    exceptions?.length > 0
      ? exceptions?.find((exception: any) => exception.code === "0001")
      : null;
  let windowsException =
    exceptions?.length > 0
      ? exceptions?.find((exception: any) => exception.code === "0005")
      : null;
  let itemsWithoutStock = withoutStockException?.data || [];

  const handleClose = async () => {
    const isInUse = paymentData.giftCards.filter((x: any) => x.inUse === true)[0];
    const filterIsInUse = paymentData.giftCards.filter((x: any) => x.inUse === true)[0].inUse;
    const filterUseGiftCards = isInUse ? filterIsInUse : false
    let giftCardVariant = paymentData?.giftCards ? filterUseGiftCards : false;

    setIsOpen(false);
    if (isPay) {
      await CheckOut.generateTransaction({ giftCardVariant: giftCardVariant })
        .then(async (responseTransaction: any) => {
          if (responseTransaction.url) {
            window.location.href = responseTransaction.data.url;
          }
        })
    }
  };

  const handleClosewindow = async () => {
    setIsOpenWin(false);
  };

  const handleSubmitSecond = () => {
    let redirectUrl =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? helpers.ALVI_ROUTES.PROD_CART
        : helpers.ALVI_ROUTES.DESA_CART;
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    if (withoutStockException && !windowsException) {
      setIsOpen(true);
    }
    if (windowsException && flagWindow) {
      setIsOpenWin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderform]);

  return (
    <>
      <AlertDialog
        btnText="Continuar sin estos productos"
        btnTextSecond="Ir al carro"
        handleSubmit={handleClose}
        handleSubmitSecond={handleSubmitSecond}
        isOpen={isOpen}
        title="Cambio en productos"
      >
        <div className="without-stock">
          <p className="without-stock__notice-text">
            Hemos notado que hubo cambios en los productos de tu carro.
          </p>
          <div className="horizontal-line" style={{ margin: "5px 0" }} />
          <ul className="without-stock__ul">
            {itemsWithoutStock?.map((items: any, index: any) => {
              return (
                <li key={index} className="without-stock__li">
                  <div className="without-stock__text">{items}</div>
                  <div
                    className="horizontal-line"
                    style={{ margin: "5px 0" }}
                  />
                </li>
              );
            })}
          </ul>
          <div className="without-stock__total">
            <div className="text">Nuevo Total</div>
            <div className="price">
              ${helpers.priceFilter(totalizerData?.totalPayment?.total)}
            </div>
          </div>
        </div>
      </AlertDialog>

      <AlertDialog
        title="El horario de despacho seleccionado ya no se encuentra disponible"
        isOpen={isOpenWin}
        btnText="Aceptar"
        handleSubmit={handleClosewindow}
        handleClose={handleClosewindow}
      >
        Lo sentimos, el horario que elegiste para despachar tu compra ya no se
        encuentra disponible. Por favor, intenta seleccionando otro horario.
      </AlertDialog>
    </>
  );
};

export default WithoutStock;
