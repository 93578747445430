import React, { useContext } from 'react';
import AlertDialog from '../../../../components/AlertDialog/AlertDialog';
import { OrderformContext } from '../../contexts/orderform.provider';

const ErrorService = () => {
  const { updateErrorService, errorService } = useContext(OrderformContext);

  const refreshPage = () => {
    if (errorService.refreshPage) {
      window.location.reload();
    } else {
      updateErrorService({
        ...errorService,
        refreshPage: true,
        openErrorService: false })
    }
  };

    return (
      <div className='App'>
        <AlertDialog
          title='Problemas al cargar información'
          isOpen={ errorService.openErrorService }
          btnText={ errorService.btnText?errorService.btnText: 'Recarga' }
          handleSubmit={refreshPage}
          isLoading={false}
        >
          Lo sentimos no pudimos cargar la información completa, <br></br> por
          favor intenta recargando la página.
        </AlertDialog>
      </div>
    );
  };

  export default ErrorService;