import React, {
  useEffect,
  useRef,
  useState
} from "react";
import OneclickList from "../Oneclick/OneclickList";
import GiftCard from "../GiftCard/GiftCard";
import CoButton from "../../../../components/Buttons/CoButton";
import analytics from "../../../../utils/analytics";
import helpers from "../../../../utils/helpers";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import WaitMessage from "../Oneclick/WaitMessage";
import { BlueRadio } from "../Shipping/Shipping";

const useStyles = makeStyles((theme) => ({
  customDesktop: {
    maxWidth: 450,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  customMobile: {
    maxWidth: 380,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 16,
    boxShadow: "none",
  },
  buttonGiftcard: {
    background: "#C4C4C4",
  },
  textField: {
  },
  giftcard: {
    "& label.Mui-focused": {
      color: "#282828",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#282828",
        border: "1px solid",
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.12rem",
      fontFamily: "OPEN SANS",
    },
  },
  giftcardMobile: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#282828",
        border: "1px solid",
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.12rem",
      fontFamily: "OPEN SANS",
    },
  },
  texContainer: {
    width: '100%',
    "& label.Mui-focused": {
      color: "#282828",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#282828",
        border: "1px solid",
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "11px",
      fontFamily: "OPEN SANS",
    },
    "& .MuiIconButton-root": {
      padding: "0px !important",
    },
  },
  texContainer_error: {
    width: '100%',

    "& .MuiFormLabel-root": {
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "11px",
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "4px !important",
    },
    "& .MuiIconButton-root": {
      padding: "0px !important",
    },
  },
  full_texContainer: {
    width: '100%',
    "& label.Mui-focused": {
      color: "#282828",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#282828",
        border: "1px solid",
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: "OPEN SANS",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "11px",
      fontFamily: "OPEN SANS",
    },
    "& .MuiIconButton-root": {
      padding: "0px !important",
    },
    "& .MuiInputBase-fullWidth": {
    },
  },
}));

const placeHolderNumGift = "Ingresa número de la Gift card ";
const placeHolderPinGift = "Ingresa el Pin de la Gift card";
const maxLengthNumGift = 22;
const maxLengthNumGiftCard = 18;
const maxLengthPinGift = 6;
const maxLengthPinGiftCard = 4;

const typePayments: any = {
  oneClick: "logo-transbank-oneClick",
  webPay: "logo-transbank-webPay",
  giftcard: "logo-transbank-giftCard",
};

const PaymentDisplay = ({
  display,
  data,
  value,
  flagMessage,
  giftCard,
  modalGiftcard,
  isOpenGiftcard,
  handleChangeRadio,
  addGiftCardService,
  setGiftCard,
  setSelectedCardType,
  setIsOpenGiftcard,
  setSelectingDate
}: any) => {
  const [paymentAlert, setPaymentAlert] = useState(false);
  const myRef = useRef<HTMLDivElement>(null);

  const setter = async () => {
    const showAlert = await helpers.addCard()
    setPaymentAlert(showAlert)
    analytics.interactiveEvent({ action: 'Click', label: 'Botón agregar nueva tarjeta' })
  };

  const validatorGiftCard = () => {
    if (
      giftCard.passWordGiftCard.length >= 4 &&
      giftCard.numberGiftCard.length === 18
    ) {
      return false;
    } else {
      return true;
    }
  };

  const classes = useStyles();

  function addDashes(valor: any) {
    let a: any = valor.replace(/-/g, "");

    let res = "";
    if (a.length <= 16) {
      const separate = 4;
      res = a
        .split("")
        .map((x: any, i: any) => (i > 0 && i % separate === 0 ? "-" + x : x))
        .join("");
    } else if (a.length > 16) {
      const separate = 4;
      const temp_a = a;
      a = a.substring(0, 16);
      let temp_length = temp_a.length - 16;
      let res_temp = a
        .split("")
        .map((x: any, i: any) => (i > 0 && i % separate === 0 ? "-" + x : x))
        .join("");
      res = `${res_temp}-${temp_a.substring(temp_a.length - temp_length)}`;
    }

    return res;
  }

  useEffect(() => {
    if (isOpenGiftcard) {
      myRef.current?.scrollIntoView()
      setTimeout(function () {
        setIsOpenGiftcard(false)
      }, 7000);
    }
  }, [isOpenGiftcard, setIsOpenGiftcard]);

  return data.map((item: any, i: any) => {
    return (
      <>
        {paymentAlert && (
          <WaitMessage title="Espera un poco" isOpen={true}>
            Nos estamos conectando de forma segura con tu medio de pago.
          </WaitMessage>
        )}
        {item.state === 1 && (
          <div key={i}>
            <div className={`container-pago ${item.id === value ? 'active-border' : ''}`}>
              <div className="box-item" onClick={() => { return item.id !== value && handleChangeRadio(item.id) }}>
                <div className="margin-radio">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={value}
                    >
                      <FormControlLabel
                        value={item.id}
                        control={<BlueRadio />}
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className={typePayments[item.id]}></div>

                <div>
                  <p className="debito">{item.name}</p>
                </div>

                {display === "desktop" && (
                  <>
                    {item.id === "oneClick" && value === "oneClick" && (
                      <div className="push-item">
                        <div style={{ display: "flex" }}>
                          <a
                            className="debito"
                            onClick={setter}
                            style={{
                              textDecoration: "underline",
                              textAlign: "right",
                              display: "block",
                            }}
                          >
                            Agregar nueva tarjeta
                          </a>
                          <span className="push-item-aspa">+</span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>


              {display === "desktop" && (
                <>
                  <div
                    className={
                      item.id === "giftcard" && value === "giftcard"
                        ? helpers.getStorage("giftCardStorage")
                          ? ""
                          : "boxGiftCard"
                        : "box-item"
                    }
                  >
                    {item.id === "oneClick" && value === "oneClick" && (
                      <OneclickList
                        flagMessage={flagMessage} 
                        handleOnClick={setter}
                        setSelectedCardType={setSelectedCardType}
                        setSelectingDate={setSelectingDate}
                      />
                    )}
                    {item.id === "webPay" && value === "webPay" && (
                      <p className="webpay-plus">
                        <span className="warn-message">IMPORTANTE</span>
                        <br></br>
                        Verás <b>2 cobros</b> en tu cuenta, uno al momento de pagar y otro al momento del envío. Recuerda que el <b>primero se eliminará</b> dependiendo de los tiempos de tu banco.
                      </p>
                    )}

                    {item.id === "giftcard" &&
                      value === "giftcard" &&
                      !helpers.getStorage("giftCardStorage") && (
                        <>
                          <div className="giftCardContainer">
                            {isOpenGiftcard ? (
                              <div ref={myRef} className="alert-giftcard">
                                <div className="icon-delete icon-style">
                                  <p className="message">{modalGiftcard}</p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className={
                                giftCard.numberGiftCard.length > 0 &&
                                  giftCard.numberGiftCard.length <
                                  maxLengthNumGiftCard
                                  ? classes.texContainer_error
                                  : giftCard.numberGiftCard.length === maxLengthNumGiftCard
                                    ? classes.full_texContainer
                                    : classes.texContainer
                              }
                            >
                              <TextField
                                id="numero"
                                error={
                                  giftCard.numberGiftCard.length > 0 &&
                                    giftCard.numberGiftCard.length <
                                    maxLengthNumGiftCard
                                    ? true
                                    : false
                                }
                                label={placeHolderNumGift}
                                variant="outlined"
                                size="small"
                                type="text"
                                helperText={
                                  giftCard.numberGiftCard.length > 0 &&
                                    giftCard.numberGiftCard.length <
                                    maxLengthNumGiftCard
                                    ? "Ingresa un número válido de 18 dígitos"
                                    : ""
                                }
                                inputProps={{ maxLength: maxLengthNumGift }}
                                className={"input-giftcard-numero"}
                                value={addDashes(giftCard.numberGiftCard)}
                                onChange={(e: any) => {
                                  let valor = e.target.value.replace(/-/g, "");
                                  if (/^(\d*)$/.test(valor)) {
                                    setGiftCard({
                                      ...giftCard,
                                      numberGiftCard: valor,
                                    });
                                  }
                                }}
                                InputProps={{
                                  fullWidth: true,
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => { setGiftCard({ ...giftCard, numberGiftCard: "" }) }}
                                    >
                                      {giftCard.numberGiftCard.length > 0 && (
                                        <span
                                          className={
                                            giftCard.numberGiftCard.length > 0 &&
                                              giftCard.numberGiftCard.length < maxLengthNumGiftCard ?
                                              "x-icon-red" :
                                              "edit-icon hoverable"
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div
                              className={
                                giftCard.passWordGiftCard.length > 0 &&
                                  giftCard.passWordGiftCard.length <
                                  maxLengthPinGiftCard
                                  ? classes.texContainer_error
                                  : giftCard.passWordGiftCard.length >=
                                    maxLengthPinGiftCard
                                    ? classes.full_texContainer
                                    : classes.texContainer
                              }
                            >
                              <TextField
                                id="numero"
                                error={
                                  giftCard.passWordGiftCard.length > 0 &&
                                    giftCard.passWordGiftCard.length <
                                    maxLengthPinGiftCard
                                    ? true
                                    : false
                                }
                                label={placeHolderPinGift}
                                variant="outlined"
                                size="small"
                                type="text"
                                helperText={
                                  giftCard.passWordGiftCard.length > 0 &&
                                    giftCard.passWordGiftCard.length <
                                    maxLengthPinGiftCard
                                    ? "Ingresa un Pin válido de mínimo 4 dígitos"
                                    : ""
                                }
                                inputProps={{ maxLength: maxLengthPinGift }}
                                className={"input-giftcard-numero"}
                                value={giftCard.passWordGiftCard}
                                onChange={(e: any) => {
                                  let valor = e.target.value.replace(/-/g, "");
                                  if (/^(\d*)$/.test(valor)) {
                                    setGiftCard({
                                      ...giftCard,
                                      passWordGiftCard: valor,
                                    });
                                  }
                                }}
                                InputProps={{
                                  fullWidth: true,
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      onClick={() => { setGiftCard({ ...giftCard, passWordGiftCard: "" }) }}
                                    >
                                      {giftCard.passWordGiftCard.length > 0 && (
                                        <span
                                          className={
                                            giftCard.passWordGiftCard.length > 0 &&
                                              giftCard.passWordGiftCard.length < maxLengthPinGiftCard ?
                                              "x-icon-red" :
                                              "edit-icon hoverable"
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div style={{ width: '35%' }}>
                              <CoButton
                                className={
                                  giftCard.passWordGiftCard.length >= 4 &&
                                    giftCard.numberGiftCard.length === 18
                                    ? "color-error"
                                    : "button_giftcard"
                                }
                                disabled={validatorGiftCard()}
                                handleClick={() => {
                                  addGiftCardService();
                                }}
                                isLoading={giftCard.loading}
                              >
                                Agregar
                              </CoButton>
                            </div>
                          </div>
                          <p className="text-giftcard">
                            Al pagar con gift card, el saldo deberá cubrir el
                            total de tu compra. No podrás agregar o sustituir
                            productos después de realizar tu pedido.
                          </p>
                        </>
                      )}

                    {item.id === "giftcard" &&
                      value === "giftcard" &&
                      helpers.getStorage("giftCardStorage") && (
                        <>
                          <GiftCard setGiftCard={setGiftCard} />
                        </>
                      )}
                  </div>
                </>
              )}

              {display === "mobile" && (
                <div>
                  {item.id === "oneClick" && value === "oneClick" && (
                    <div>
                      <div className="box-item">
                        <div style={{ width: '100%' }}>
                          <OneclickList
                            flagMessage={flagMessage}
                            handleOnClick={setter}
                            setSelectedCardType={setSelectedCardType}
                            setSelectingDate={setSelectingDate}
                          />
                        </div>
                      </div>
                      <div className="box-item" style={{ width: '100%' }}>
                        <div style={{ display: "flex", alignItems: 'center', margin: '0 auto' }}>
                          <a
                            className="debito"
                            onClick={setter}
                            style={{
                              textDecoration: "underline",
                              display: "block",
                            }}
                          >
                            Agregar nueva tarjeta
                          </a>
                          <span className="push-item-aspa-mobile">+</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {item.id === "webPay" && value === "webPay" && (
                    <div className="box-item">
                      <p className="webpay-plus">
                        <span className="warn-message">IMPORTANTE</span>
                        <br></br>
                        Verás <b>2 cobros</b> en tu cuenta, uno al momento de pagar y otro al momento del envío. Recuerda que el <b>primero se eliminará</b> dependiendo de los tiempos de tu banco.
                      </p>
                    </div>
                  )}
                  {item.id === "giftcard" &&
                    value === "giftcard" &&
                    !helpers.getStorage("giftCardStorage") && (
                      <>
                        <div>
                          {isOpenGiftcard ? (
                            <div ref={myRef} className="alert-giftcard">
                              <div className="icon-delete icon-style">
                                <p className="message">{modalGiftcard}</p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              giftCard.numberGiftCard.length > 0 &&
                                giftCard.numberGiftCard.length <
                                maxLengthNumGiftCard
                                ? classes.texContainer_error
                                : giftCard.numberGiftCard.length ===
                                  maxLengthNumGiftCard
                                  ? classes.full_texContainer
                                  : classes.texContainer
                            }
                          >
                            <TextField
                              id="numero"
                              error={
                                giftCard.numberGiftCard.length > 0 &&
                                  giftCard.numberGiftCard.length <
                                  maxLengthNumGiftCard
                                  ? true
                                  : false
                              }
                              label={placeHolderNumGift}
                              variant="outlined"
                              size="small"
                              type="text"
                              helperText={
                                giftCard.numberGiftCard.length > 0 &&
                                  giftCard.numberGiftCard.length <
                                  maxLengthNumGiftCard
                                  ? "Ingresa un número válido de 18 dígitos"
                                  : ""
                              }
                              inputProps={{ maxLength: maxLengthNumGift }}
                              className={"input-giftcard-numero-mobile"}
                              value={addDashes(giftCard.numberGiftCard)}
                              onChange={(e: any) => {
                                let valor = e.target.value.replace(/-/g, "");
                                if (/^(\d*)$/.test(valor)) {
                                  setGiftCard({
                                    ...giftCard,
                                    numberGiftCard: valor,
                                  });
                                }
                              }}
                              InputProps={{
                                fullWidth: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {giftCard.numberGiftCard.length > 0 && (
                                      <IconButton
                                        onClick={() => {
                                          setGiftCard({
                                            ...giftCard,
                                            numberGiftCard: "",
                                          });
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          className={
                                            giftCard.numberGiftCard.length >
                                              0 &&
                                              giftCard.numberGiftCard.length <
                                              maxLengthNumGiftCard
                                              ? "textoError"
                                              : "textoOk"
                                          }
                                        ></CancelOutlinedIcon>
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div
                            className={
                              giftCard.passWordGiftCard.length > 0 &&
                                giftCard.passWordGiftCard.length <
                                maxLengthPinGiftCard
                                ? classes.texContainer_error
                                : giftCard.passWordGiftCard.length >=
                                  maxLengthPinGiftCard
                                  ? classes.full_texContainer
                                  : classes.texContainer
                            }
                          >
                            <TextField
                              id="numero"
                              error={
                                giftCard.passWordGiftCard.length > 0 &&
                                  giftCard.passWordGiftCard.length <
                                  maxLengthPinGiftCard
                                  ? true
                                  : false
                              }
                              label={placeHolderPinGift}
                              variant="outlined"
                              size="small"
                              type="text"
                              helperText={
                                giftCard.passWordGiftCard.length > 0 &&
                                  giftCard.passWordGiftCard.length <
                                  maxLengthPinGiftCard
                                  ? "Ingresa un Pin válido de mínimo 4 dígitos"
                                  : ""
                              }
                              inputProps={{ maxLength: maxLengthPinGift }}
                              className={"input-giftcard-numero-mobile"}
                              value={giftCard.passWordGiftCard}
                              onChange={(e: any) => {
                                let valor = e.target.value.replace(/-/g, "");
                                if (/^(\d*)$/.test(valor)) {
                                  setGiftCard({
                                    ...giftCard,
                                    passWordGiftCard: valor,
                                  });
                                }
                              }}
                              InputProps={{
                                fullWidth: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {giftCard.passWordGiftCard.length > 0 && (
                                      <IconButton
                                        onClick={() => {
                                          setGiftCard({
                                            ...giftCard,
                                            passWordGiftCard: "",
                                          });
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          className={
                                            giftCard.passWordGiftCard.length >
                                              0 &&
                                              giftCard.passWordGiftCard.length <
                                              maxLengthPinGiftCard
                                              ? "textoError"
                                              : "textoOk"
                                          }
                                        ></CancelOutlinedIcon>
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div>
                            <CoButton
                              className={
                                giftCard.passWordGiftCard.length >= 4 &&
                                  giftCard.numberGiftCard.length === 18
                                  ? "color-error"
                                  : "button_giftcard-mobile"
                              }
                              disabled={validatorGiftCard()}
                              handleClick={() => {
                                addGiftCardService();
                              }}
                              isLoading={giftCard.loading}
                            >
                              Agregar
                            </CoButton>
                          </div>
                        </div>
                        <p className="text-giftcard">
                          Al pagar con gift card, el saldo deberá cubrir el
                          total de tu compra. No podrás agregar o sustituir
                          productos después de realizar tu pedido.
                        </p>
                      </>
                    )}
                  {
                    item.id === "giftcard" &&
                    value === "giftcard" &&
                    helpers.getStorage("giftCardStorage") &&
                    <GiftCard setGiftCard={setGiftCard} />
                  }
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  });
};

export default PaymentDisplay;
