import React, {
  useEffect,
  useRef,
  useState
} from "react";
import "./style.scss";

let isOpen = false
let isStatusOk = false
let toastMessage = ''

interface Props {
  bottom?: string
  left?: string
  margin?: string
  right?: string
  top?: string,
  width?: string
}

const activeToast = ({ message, statusOk }: { message: string, statusOk: boolean }) => {
  isOpen = true
  toastMessage = message
  isStatusOk = statusOk
}

const Toast = ({
  bottom,
  left,
  margin,
  right,
  top,
  width,
}: Props) => {
  const toastRef = useRef<HTMLDivElement>(null)
  const [hideToast, setHideToast] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setHideToast(true)
      setTimeout(function () {
        isOpen = false
        setHideToast(false)
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {hideToast ? (
        <div
          ref={toastRef}
          className={`${isStatusOk ? '' : 'failed'} registration-alert`}
          style={{
            bottom: bottom,
            left: left,
            right: right,
            top: top,
            width: width,
            margin: margin,
          }}
        >
          <div className={`${isStatusOk ? 'success-toast' : 'error-toast'} icon-style`} />
          <p className='registration-message'>
            {toastMessage}
          </p>
          <div
            className='x-toast'
            onClick={() => {
              isOpen = false
              return setHideToast(false)
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export { Toast, activeToast }
