import React, {
    useContext,
    useEffect,
    useState
} from "react";
import { OrderformContext } from "../../../contexts/orderform.provider";
import EditAction from "../../../../../components/EditAction/EditAction";
import helpers from "../../../../../utils/helpers";

/**
  Component to render the delivery window selected by the customer.
  @param {Object} props - Component properties.
  @param {boolean} props.isMobile - Indicates if the view is for a mobile device.
  @param {Function} props.handleClick - Function that handles the click on the edit button.
  @param {boolean} props.isHiddenWindowEditor - Indicates if the edit button should be hidden.
  @returns {JSX.Element} React element that renders the delivery window.
*/
export const RenderDeliveryWindow = ({
    isMobile,
    isHiddenWindowEditor,
    handleClick = () => { return },
    searchFirstWindowWithListPriceZero = () => { return },
  }: {
    isMobile: boolean;
    handleClick: Function;
    searchFirstWindowWithListPriceZero?: Function;
    isHiddenWindowEditor: boolean;
  }): JSX.Element => {
    const { orderform } = useContext(OrderformContext);
    const {
      shippingData: { logisticsInfo },
    } = orderform;
    const [deliveryWindow, setDeliveryWindow] = useState(logisticsInfo[0]?.slas[0]?.deliveryWindow)
    const { startDateUtc, endDateUtc } = deliveryWindow || {};
    const startDate = new Date(startDateUtc);
    const endDate = new Date(endDateUtc);

    useEffect(() => {
      const selectedDeliveryWindows = logisticsInfo[0]?.slas[0]?.deliveryWindow
      if (selectedDeliveryWindows) {
        setDeliveryWindow(selectedDeliveryWindows)
      } else {
        const availableDeliveryWindows = logisticsInfo[0]?.slas[0]?.availableDeliveryWindows;
        const isPickup = logisticsInfo?.[0]?.selectedDeliveryChannel === "pickup-in-point"
        const firstAvalaibleWindows = searchFirstWindowWithListPriceZero(availableDeliveryWindows, isPickup)
        setDeliveryWindow(firstAvalaibleWindows)
      }
    }, [logisticsInfo, orderform])

    return (
      <>
        {helpers.DAYS[startDate.getDay()] && (
          <div className="delivery-window">
            <div className="delivery-window__text">
              <span className="icon-clock" style={{ padding: "0 5px" }} />
              <span className="b">
                {helpers.DAYS[startDate.getDay()]} {startDate.getDate()}/
                {("0" + (startDate.getMonth() + 1)).slice(-2)} entre las{" "}
                {helpers.timeUTCFilter(startDate)} y{" "}
                {helpers.timeUTCFilter(endDate)} hrs.
              </span>
            </div>
            {isMobile ? (
              <button
                id="showOrHide"
                className="btn-change"
                onClick={() => {
                  handleClick();
                }}
              >
                Editar fecha y hora
              </button>
            ) : (
              (
               <EditAction text={"Editar fecha y hora"} handleClick={handleClick} />
              )
            )}
          </div>
        )}
      </>
    );
  };
  