import React, {
  useContext,
  useState,
  useEffect
} from "react";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import CoTextField from "../../../../components/TextField/CoTextField";
import EditAction from "../../../../components/EditAction/EditAction";
import AlertDialogModal from "../../../../components/AlertDialogModal/AlertDialogModal";
import CheckOut from "../../services/CheckOut.service";
import { OrderformContext } from "../../contexts/orderform.provider";
import analytics from "../../../../utils/analytics";
import helpers from "../../../../utils/helpers";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { BlueRadio } from "../Shipping/Shipping";
import "./whoreceives.styles.scss";


const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "row"
  },
  label: {
    marginRight: "25px"
  },
  iconDelete: {
    color: "#F80000",
    marginLeft: "-10px"
  },
  radioFix: {
    alignItems: 'center',
    display: "flex",
    marginRight: "5px",
    marginLeft: "90px",
    marginTop: "-15px",
  },
  nameRadioFix: {
    maxWidth: "94px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  radioContainer: {
    display: "flex"
  },
  editMsg: {
    fontSize: "0.8rem",
    textDecoration: "underline",
    margin: 0
  },
  fixpen: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "4px"
  },
  mobileModal: {
    maxWidth: "295px",
    margin: "20px"
  },
  whoRecievesModal: {
    "& .MuiDialog-paper": {
      overflowY: 'unset',
      borderRadius: '10px',
      width: '516px'
    },
    '& .MuiButtonBase-root': {
      top: '0px',
      right: '0px'
    }
  },
}));

const WhoReceives = (props: any) => {
  const { isStore } = props;
  const [open, setOpen] = React.useState(false);

  const {
    setShippingData,
    orderform,
    orderform: {
      clientProfileData,
      shippingData: {
        address
      },
    },
  } = useContext(OrderformContext);

  const [isLoading, setIsLoading] = useState(false);

  const [nameChange, setNameChange] = useState(false)

  const receiverName = orderform?.shippingData?.address?.receiverName || ''
  const firstName = orderform?.clientProfileData?.firstName || ''
  const lastName = orderform?.clientProfileData?.lastName || ''
  let defaultReceiverName = { name: '' };
  if (
    helpers.withoutAccent(receiverName?.split(' ')[0]) !== helpers.withoutAccent(firstName) &&
    helpers.withoutAccent(receiverName?.split(' ')[1]) !== helpers.withoutAccent(lastName)
  ) {
    defaultReceiverName = {
      name: receiverName?.split(' '),
    };
  }

  const [formWhoReceives, setFormWhoReceives] = useState(defaultReceiverName);
  const { name } = formWhoReceives;

  let initialStateRadioValue = "Otra persona";

  if (!name || isStore) {
    initialStateRadioValue = "Yo";
  }
  const [radioValue, setRadioValue] = useState(initialStateRadioValue);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const iconChange = () => {
    setNameChange(false)
  }

  const handleXClick = (isName: boolean) => {
    setFormWhoReceives({ ...formWhoReceives, [isName ? 'name' : 'surname']: '' })
    isName ?? setNameChange(false)
  }

  const handleClose = () => {
    setOpen(false);
    iconChange()
    const fullName = `${helpers.withoutAccent(clientProfileData.firstName)} ${helpers.withoutAccent(clientProfileData.lastName)}`;
    if (receiverName !== fullName) {
      setFormWhoReceives({
        name: receiverName.split(" ")[0] || " "
      });
    } else {
      setRadioValue("Yo");
      deleteWhoReceivesForm();
    }
  };

  // Cuando recibe alguien diferente
  const handleSubmit = (nameWhoReceive: any) => {
    const validateArray = Array.isArray(nameWhoReceive)
    analytics.interactiveEvent({ action: 'Click ingresar receptor pedido', label: 'Modal botón ingresar receptor pedido' })
    if (!nameWhoReceive || !nameWhoReceive?.length) {
      setOpen(false)
      return setIsOpenDeleteDialog(true)
    }
    setIsLoading(true);
    CheckOut.updateReceiver({
      address: {
        ...address,
        receiverName: validateArray ? nameWhoReceive.join(" ") : nameWhoReceive
      }
    })
      .then(async (response: any) => {
        if (!response) return setIsOpenDeleteDialog(true);
        if (response.error) {
          setOpen(false)
          return () => {
            setIsOpenDeleteDialog(true)
          }
        }
        if (response?.failed?.length === 0) {
          setShippingData(response.shippingData)
          setOpen(false);
          iconChange()
        } else {
          setIsOpenDeleteDialog(true);
          if (response?.failed) {
            throw Error(response.failed.join(', '));
          }
        }
      })
      .catch((_error) => {
        setIsOpenDeleteDialog(true);
      });
  };

  const deleteWhoReceivesForm = () => {
    setFormWhoReceives({
      name: ""
    });
  };

  const handleClose2 = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleChange = (event: any) => {
    if (event.target.value === "Yo") {
      setIsLoading(true)
      CheckOut.updateReceiver({
        address: {
          ...address,
          receiverName: helpers.withoutAccent(clientProfileData.firstName) + " " + helpers.withoutAccent(clientProfileData.lastName)
        }
      })
        .then(async (response: any) => {
          if (response.failed.length === 0) {
            setRadioValue("Yo");
            setOpen(false);
            deleteWhoReceivesForm();
            setShippingData(response.shippingData)
            setIsLoading(false)
            return response;
          } else {
            setIsOpenDeleteDialog(true);
            setIsLoading(false)
            throw Error(response.failed.join(', '));
          }
        })
        .catch((_error) => {
          setIsOpenDeleteDialog(true);
          setIsLoading(false)
        });
    } else {
      setRadioValue(event.target.value);
    }
  };

  const handleChangeForm = (event: any) => {
    setFormWhoReceives({
      ...formWhoReceives,
      [event.target.name]: event.target.value,
    });
  };

  const delValue = (nameToDelete: string) => {
    setFormWhoReceives({
      ...formWhoReceives,
      [nameToDelete]: "",
    });
  };

  const whoReceivesTag = (tagInfo: string) => {
    analytics.interactiveEvent({ action: `Click - ${tagInfo}`, label: 'Receptor pedido' })
  }

  const classes = useStyles();

  useEffect(() => {
    if (open === false) {
      setIsLoading(false)
    } else {
      analytics.interactiveEvent({ action: 'Carga modal receptor pedido', label: 'Modal receptor pedido' })
    }
  }, [open]);

  // It is used only when it is the customer's first purchase or there is a new orderForm because it arrives empty
  useEffect(() => {
    if (!receiverName || receiverName === 'undefined' || receiverName === 'null') {
      CheckOut.updateReceiver({
        address: {
          ...address,
          receiverName: helpers.withoutAccent(clientProfileData.firstName) + " " + helpers.withoutAccent(clientProfileData.lastName)
        }
      })
        .then(async (response: any) => {
          if (response.failed.length === 0) {
            setShippingData(response.shippingData)
            setFormWhoReceives({
              name: ""
            });
            setRadioValue("Yo");
            setOpen(false);
          } else {
            setIsOpenDeleteDialog(true);
            throw Error(response.failed.join(', '));
          }
        })
        .catch((_error) => {
          setIsOpenDeleteDialog(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverName]);

  return (
    <div className="who-receives">
      <p className="title">¿Quién {isStore ? 'retira la compra' : 'recibirá el pedido'}?</p>

      <FormControl component="fieldset">
        <RadioGroup
          className={classes.root}
          aria-label="gender"
          name="who-receives"
          value={radioValue}
          onChange={handleChange}
        >
          {
            isLoading ? (<p style={{ marginRight: '10px' }} >Cargando...</p>) : (
              <>
                <FormControlLabel
                  className={classes.root}
                  onClick={() => whoReceivesTag('Yo')} // esto solo ejecuta analitica de google
                  value="Yo"
                  control={<BlueRadio />}
                  label="Yo" // Escritorio
                />
                {!isStore && (
                  <>
                    <FormControlLabel
                      value="Otra persona"
                      control={<BlueRadio />}
                      onClick={() => {
                        whoReceivesTag('Otra persona')
                        handleClickOpen() // pasa el open a true
                      }}
                      label='Otra persona'
                    />

                    {name && (
                      <div className={classes.radioFix}>
                        <span className={classes.nameRadioFix}>{name.slice(0, 15)}</span>&nbsp;{" - "}&nbsp;
                        <EditAction handleClick={() => { setOpen(true) }} text="Editar" />
                      </div>
                    )}

                  </>
                )}
              </>
            )
          }
        </RadioGroup>
      </FormControl>

      <AlertDialogModal isOpen={isOpenDeleteDialog} handleClose={handleClose2}>
        <HighlightOffIcon className={classes.iconDelete}></HighlightOffIcon>
        <p
          style={{
            textAlign: "justify",
            padding: "2px 65px",
            marginTop: "-28px",
            marginLeft: "-26px",
          }}
        >
          Lo sentimos, no pudimos guardar los datos ingresados. Por favor,
          inténtalo nuevamente.
        </p>
      </AlertDialogModal>

      <AlertDialog
        btnText="Guardar datos"
        handleClose={handleClose}
        handleSubmit={() => { handleSubmit(name) }}
        isLoading={isLoading}
        isOpen={open}
        maxWidth='md'
        modalType='form'
        disabled={!name}
        title="Agrega persona que recibe"
      >
        <p style={{ marginTop: '-2px' }}>
          Si no estás disponible para recibir tu pedido, lo dejaremos con:
        </p>
        <div className="dialog-form">
          <CoTextField
            delValue={delValue}
            handleChange={(event: object) => {
              handleChangeForm(event)
              setNameChange(true)
            }}
            maxLength={15}
            endAdornment={nameChange && <span onClick={() => handleXClick(true)} className={"x-icon-red"} />}
            label='Nombre de quién recibe'
            name='name'
            value={name?.slice(0, 15)}
          />
        </div>
      </AlertDialog>
    </div>
  );
};

export default WhoReceives;
