import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import "./WindowEditorDesktop/WindowEditorDesktop.styles.scss";

const useStyles = makeStyles(() => ({
  root: {
    color: "#02498E",
    marginTop: "3px",
  },
}));

const Slot = (props: any) => {

  const { cost, isAvailable, isActive, handleClick, isLoading } = props
  const classes = useStyles()

  let filteredCost
  let classSlot = `slot cost ${isActive ? "active" : ""} ${isAvailable ? "" : "unavailable"}`
  let contentSlot

  if (cost === 0) {
    filteredCost = "Gratis"
  } else {
    filteredCost = `$${cost}`
  }

  if (!isAvailable) {
    contentSlot = <div className="unavailable-line" />;
  } else if (isLoading && isActive) {
    contentSlot = <CircularProgress className={classes.root} size={20} />;
  } else if (isLoading && !isActive) {
    contentSlot = filteredCost;
  } else if (!isLoading) {
    contentSlot = filteredCost;
  }

  if (isLoading) {
    return <div className={classSlot}>{contentSlot}</div>;
  } else {
    return (
      <div onClick={() => isAvailable && handleClick()} className={classSlot}>
        {contentSlot}
      </div>
    );
  }
};
export default Slot;
