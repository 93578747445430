import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { PhoneTextField } from "../../../../components/PhoneTextField";
import Container from "../Container/Container";
import { OrderformContext } from "../../contexts/orderform.provider";
import CheckOut from "../../services/CheckOut.service";
import helpers from "../../../../utils/helpers";
import analytics from "../../../../utils/analytics";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import "./profile.styles.scss";

interface Props {
  isNumberPhoneFull: boolean,
  isSelectingDate: boolean,
  setIsNumberPhoneFull: React.Dispatch<React.SetStateAction<boolean>>
  setSelectingDate: React.Dispatch<React.SetStateAction<boolean>>
}

const Profile = ({
  isNumberPhoneFull,
  setIsNumberPhoneFull,
  setSelectingDate,
}: Props) => {

  const {
    updateValidSections,
    updateOrderFormData,
    orderform: { clientProfileData },
  } = useContext(OrderformContext);
  const { firstName, lastName, phone: phoneDefault } = clientProfileData;
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const handleChange = (event: { target: { value: any; name: any } }) => {
    const { value } = event.target;
    analytics.interactiveEvent({
      action: 'numero-contacto', label: 'editar numero contacto'
    })
    if (/^(\d*)$/.test(value) && value.length < 9) {
      setPhone(value);
    }
  };

  const isValidPhone = (phoneToValidate: string) => {
    if (!phoneToValidate) return false;
    if (/^(\d*)$/.test(phoneToValidate) && phoneToValidate.length === 8) {
      setError("")
      setIsNumberPhoneFull(true)
      setSelectingDate(false)
      return true;
    }
    else {
      setIsNumberPhoneFull(false)
      setSelectingDate(true)
      return false
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (`+569${value}` === phoneDefault) return
    if (!isValidPhone(value) || value === '' || value.length < 8) {
      setSelectingDate(false)
      setIsNumberPhoneFull(false)
      setError("Ingrese un número válido");
      setIsOpenDeleteDialog(true);
      return
    }
    if (isValidPhone(value) && error !== '') {
      setError("");
    }

    const dataSend = { ...clientProfileData, phone: `+569${value}` };
    CheckOut.updateProfile(dataSend).then((response: any) => {
      if (response?.error) {
        setIsOpenDeleteDialog(true);
        return
      }
      updateOrderFormData({ clientProfileData: response?.clientProfileData })
      return response;
    })
    if (error !== '') setError("");
  };

  const handleClose = () => {
    setIsOpenDeleteDialog(false);
  };

  //Validate Sections, for Ready to pay status
  useEffect(() => {
    if (!error) {
      updateValidSections(helpers.SECTIONS.PROFILE, true);
    } else {
      updateValidSections(helpers.SECTIONS.PROFILE, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isOpenDeleteDialog]);

  useEffect(() => {
    isValidPhone(phone)
  }, [phone])

  useEffect(() => {
    const initialValidation = () => {
      let valuePhone = clientProfileData.phone
      if (/\+/.test(valuePhone)) valuePhone = valuePhone?.slice(4)
      setPhone(valuePhone);
      if (isValidPhone(valuePhone)) {
        setError("");
        updateValidSections(helpers.SECTIONS.PROFILE, true);
      } else {
        updateValidSections(helpers.SECTIONS.PROFILE, false);
        setError("Ingrese un número válido");
        setIsOpenDeleteDialog(true);
        handleClose();
      }
    };

    initialValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AlertDialog
        btnText="Aceptar"
        isLoading={false}
        isOpen={isOpenDeleteDialog}
        modalType='alert'
        title={'Número ingresado no válido'}
        handleClose={() => { setIsOpenDeleteDialog(false) }}
        handleSubmit={() => { setIsOpenDeleteDialog(false) }}
      >
        Lo sentimos, no pudimos guardar el número ingresado. Por favor,
        inténtalo nuevamente.
      </AlertDialog>

      <Container title="Datos personales:">
        <div className="personal-info">
          <p className="fontWeight-600">
            Nombre y Apellido
          </p>
          <p className="email">{firstName} {lastName}</p>
        </div>
        <div className="phone-info">
          <div className="phone-info__number">
            <p className="phone-info__number-text">
              Número de contacto
            </p>
            <PhoneTextField
              phone={phone}
              handleChange={handleChange}
              handleOnBlur={handleOnBlur}
              error={error}
            />
          </div>
          <p className="phone-info__notice">
            Usaremos este número si necesitamos contactarte durante esta compra.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Profile;
