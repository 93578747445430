import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: '#282828',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
      },
    },
  },
  menu: {
    whiteSpace: "pre-wrap",
  },
}));

const CoListTextField = ({
  name,
  value,
  items,
  placeholder,
  handleChange,
  readOnly,
  errorMessage
}: {
  name: string;
  value: string;
  items: any;
  placeholder?: string;
  handleChange?: any;
  handleOnBlur?: any;
  handleOnFocus?: any;
  readOnly?: boolean,
  otherProps?: any;
  errorMessage?: string;
}) => {
  const classes = useStyles();
  let renderedItems = Object.keys(items).map((item) => {
    return (
      <MenuItem className={classes.menu} key={item} value={item}>
        {items[item]}
      </MenuItem>
    );
  });

  return (
    <>
      <FormControl className={classes.root} fullWidth={true} variant="outlined" >
        <InputLabel id={`${name}-label`}>{placeholder}</InputLabel>
        <Select
          label={placeholder}
          labelId={`${name}-label`}
          id={`${name}-id`}
          value={value}
          fullWidth={true}
          name={name}
          onChange={handleChange}
          inputProps={{ readOnly: readOnly }}
          error={Boolean(errorMessage)}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {renderedItems}
        </Select>
      </FormControl>
    </>
  );
};

export default CoListTextField;
