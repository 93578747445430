import React from "react";

interface ITotalizerRow {
    isTotal?: boolean
    item: string
    value: string
}
export const TotalizerRow = (data: ITotalizerRow) => {
    const {
        item,
        value,
        isTotal
    } = data
    return (
      <div
        className="totalizer-row"
        style={isTotal ? { fontWeight: "bolder" } : {}}
      >
        <div className="totalizer-row__item">{item}</div>
        <div className="value">{value}</div>
      </div>
    );
  };