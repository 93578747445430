import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './App.scss';
import OrderformProvider from './scenes/CheckOut/contexts/orderform.provider'

ReactDOM.render(
    <OrderformProvider>
        <App />
    </OrderformProvider>
    , document.getElementById('root')
);
serviceWorker.unregister();
