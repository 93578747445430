import React, {
  useContext,
  useEffect,
  useState
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import analytics from "../../../../utils/analytics";
import helpers from "../../../../utils/helpers";
import { OrderformContext } from "../../contexts/orderform.provider";
import CheckOut from "../../services/CheckOut.service";
import "./oneclick_card.styles.scss";

const OneClickCard = ({
  cardOneclick = {},
  setSelectedCardType,
  setSelectingDate,
  getCards = () => { return },
}: any) => {
  const {
    updateOrderFormData,
    setSelectedCard,
    cardsOneClick,
    isSelectedCardId,
    setCardOneClick,
    orderform: { middlewarecard, paymentData }
  } = useContext(OrderformContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateCards, setIsUpdateCards] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const inscriptionId = isSelectedCardId || middlewarecard?.inscriptionId
  const selected = (inscriptionId === cardOneclick._id) && paymentData.payments.length > 0

  let flagtrahsClicker = false;
  const trashIconStyle = {
    width: "15.45px",
    height: "15.45px",
    backgroundSize: "15.45px",
    margin: "auto",
    paddingTop: "18.45px",
  };

  const handleClickSelect = async () => {
    analytics.interactiveEvent({
      action: 'seleccionar-medio-pago',
      cardType: helpers.setCardType(cardOneclick.type),
      label: helpers.setCardType(cardOneclick.type),
      paymentType: 'oneClick'
    })
    setSelectingDate(true)
    if (!flagtrahsClicker) {
      setIsLoading(true);
      const dataPayment = {
        inscriptionType: cardOneclick.type,
        inscriptionId: cardOneclick._id
      }
      setSelectedCard(cardOneclick._id)
      const codePaymentRule: any = { 'debit': 201, 'credit': 202 }
      const codePayment = codePaymentRule[cardOneclick.type] as number
      const { paymentData: { payments } } = await CheckOut.updateSelectedPaymentType(codePayment ?? 202)
      const { customData } = await CheckOut.updateCustomData(dataPayment, 'inscriptioncard')
      updateOrderFormData({
        customData,
        paymentData: {
          ...paymentData,
          payments
        }
      })
      setIsLoading(false);
    }
    setSelectingDate(false)
  };

  const handleClickDelete = () => {
    flagtrahsClicker = true;
    setIsOpenDeleteDialog(true);
  };

  const handleClose = () => {
    analytics.interactiveEvent({ action: 'Click eliminar tarjeta', label: 'Eliminar tarjeta' })
    setIsOpenDeleteDialog(false);
  };

  const handleSubmitDelete = async (idCard: string) => {
    try {
      setIsLoading(true);
      CheckOut.deleteCard(idCard)
      await new Promise(resolve => setTimeout(resolve, 4000))
      await getCards()
      handleClose();
      setIsLoading(false);
      setIsUpdateCards(true)
      if (cardsOneClick?.length === 1) {
        setCardOneClick([])
      }
    } catch (error) {
      console.error(error)
      if (cardsOneClick?.length === 1) {
        setCardOneClick([])
      }
    }
  };

  useEffect(() => {
    if (!isLoading && isUpdateCards) {
      getCards()
      setIsUpdateCards(false)
    }
  }, [getCards, isLoading, isUpdateCards])

  useEffect(() => {
    if (!selected || (typeof setSelectedCardType !== 'function')) return
    setSelectedCardType(cardOneclick.cardType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <>
      <AlertDialog
        btnTextSecond='Cancelar'
        btnText="Eliminar"
        handleClose={handleClose}
        handleSubmit={() => {
          handleSubmitDelete(cardOneclick._id);
        }}
        handleSubmitSecond={handleClose}
        isLoading={isLoading}
        isOpen={isOpenDeleteDialog}
        modalType='alert'
        title="¿Estás seguro que quieres eliminar esa tarjeta?"
      >
        Si la eliminas no la podrás usar de nuevo para compras en Alvi a
        menos que vuelvas a añadirla.
      </AlertDialog>

      <div
        className={`oneclick-card ${selected ? "active" : ""}`}
        onClick={handleClickSelect}
      >
        {isLoading && (
          <CircularProgress className="loader-card" size={40} />
        )}
        <div className="trash-icon__wrapper">
          <div className={`trash-icon__circle icon${selected ? "-active" : ""}`}>
            <div
              className="trash-icon"
              onClick={handleClickDelete}
              style={trashIconStyle}
            ></div>
          </div>
        </div>
        <div className="oneclick-card__content">
          <div className={`${cardOneclick.cardType.toLowerCase()}-logo`} />
          <div className="oneclick-card__card-number">
            {helpers.cardNumberFilter(cardOneclick.cardNumber)}
          </div>
        </div>
      </div>
    </>
  );
};

export default OneClickCard;
