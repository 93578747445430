import React, { useState, useEffect, useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { OrderformContext } from "../../../contexts/orderform.provider";
import helpers from "../../../../../utils/helpers";
import {
  getScheduleByTimeWindow,
  getUniqueDays,
  getUniqueTimewindows as getUniqueTimeWindows,
} from "../shipping.utils";
import CheckOut from "../../../services/CheckOut.service";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AlertDialogModal from "../../../../../components/AlertDialogModal/AlertDialogModal";
import AlertDialogRedirect from "../../../../../components/AlertDialogRedirect/AlertDialogRedirect";
import SlotMobile from "./slotMobile";
import { Week } from "./Week";

interface IWindowEditorMobileProps {
  isDelivery?: boolean;
  isLoading?: boolean;
  setisLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDeliveryWindowTag?: (tagInfo: any) => void;
}

export const WindowEditorMobile = ({
  isDelivery = false,
  isLoading,
  setisLoading = () => {
    return;
  },
  selectedDeliveryWindowTag = () => {},
}: IWindowEditorMobileProps) => {
  // STATE
  const [isOpenError, setIsOpenError] = useState(false);
  const [scheduledRow, setScheduledRow] = useState([] as any);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [windows, setWindows] = useState([] as any);
  let position: any = 0;
  let timeTempHour: any = {};
  let array_window_send: any = [];
  let scheduledRows: any = [];

  const { orderform, setShippingData, setTotalizer } =
    useContext(OrderformContext);

  const {
    shippingData,
    shippingData: { logisticsInfo },
  } = orderform;

  const isPickup =
    shippingData?.logisticsInfo?.[0]?.selectedDeliveryChannel ===
    "pickup-in-point";
  const availableDeliveryWindows =
    logisticsInfo[0]?.slas[0]?.availableDeliveryWindows;

  const daysDeliveryWindows = availableDeliveryWindows?.filter((sla: any) => {
    if (isPickup) return sla?.windows?.[0]?.lisPrice === 0;
    return sla?.windows?.[0]?.lisPrice > 0;
  });

  let firstDate = new Date(
    daysDeliveryWindows?.[0]?.windows?.[0]?.startDateUtc
  );
  const deliveryWindow = logisticsInfo[0]?.slas[0]?.deliveryWindow;

  let timeWindows = getUniqueTimeWindows(daysDeliveryWindows);
  let days = getUniqueDays(daysDeliveryWindows); // formato Jue 1, Vie 2, so on
  let schedule = getScheduleByTimeWindow(
    timeWindows,
    days,
    daysDeliveryWindows
  );

  const positionWindow = async (selectedWindow: any): Promise<number> => {
    let uniqueDates: string[] = [];
    availableDeliveryWindows.map((deliveryWindow: any) => {
      const startDate = new Date(deliveryWindow.windows[0].startDateUtc)
        .toISOString()
        .replace(/T/, " ")
        .replace(/\.000Z/, "");
      let index = uniqueDates.indexOf(startDate.substring(0, 10));
      if (index < 0) {
        uniqueDates.push(startDate.substring(0, 10));
      }
    });
    const selectedStartDate = new Date(selectedWindow.startDateUtc)
      .toISOString()
      .replace(/T/, " ")
      .replace(/\.000Z/, "");
    let position: number =
      uniqueDates.indexOf(selectedStartDate.substring(0, 10)) || 0;
    return position;
  };

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        color: "#02498E",
        marginLeft: "-30px",
        paddingTop: "30px",
      },
    })
  );
  // Build handle click function for Slot Component
  const updateDeliveryWindowState = (selectedDeliveryWindow: any) => {
    let updatedShippingData = { ...shippingData };
    updatedShippingData.logisticsInfo[0].slas[0].deliveryWindow =
      selectedDeliveryWindow;
    setShippingData(updatedShippingData);
  };
  const handleClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleClick = async (selectedDeliveryWindow: any) => {
    selectedDeliveryWindowTag(selectedDeliveryWindow)
    positionWindow(selectedDeliveryWindow);

    setisLoading(true);
    const prevShippingData = { ...shippingData };

    updateDeliveryWindowState(selectedDeliveryWindow);
    setisLoading(true);
    handleClose();

    await CheckOut.updateDeliveryWindow({
      ...selectedDeliveryWindow,
    }).then(async (response: any) => {
      if (response.failed.length === 0) {
        if (response?.totalizers) setTotalizer(response.totalizers)
        let availableDeliveryWindows_temp: any = response?.shippingData?.logisticsInfo[0]?.slas[0]?.availableDeliveryWindows;
        const daysDeliveryWindows_temp = availableDeliveryWindows_temp?.filter((sla: any) => {
          if (isPickup) return sla?.windows?.[0]?.lisPrice === 0
          return sla?.windows?.[0]?.lisPrice > 0
        })
        let deliveryWindow_temp: any = response?.shippingData?.logisticsInfo[0]?.slas[0]?.deliveryWindow;
        let schedule_send = getScheduleByTimeWindow(
          timeWindows,
          days,
          daysDeliveryWindows_temp
        );
        init(schedule_send, deliveryWindow_temp);
        return response;
      } else {
        setIsOpenDeleteDialog(true);
      }
    }).then((_response) => {
      setisLoading(false);
    }).catch((_error) => {
      setIsOpenError(true)
      setShippingData(prevShippingData)
    })
  }

  
  const init = (schedule: any, deliveryWindow_temp: any = {}) => {
    setScheduledRow([]);
    scheduledRows = [];
    // eslint-disable-next-line
    schedule.map((scheduledRowItem: any, indexScheduledRow: any) => {
      let renderSlots = scheduledRowItem.map(
        (scheduledWindow: any, indice: any) => {
          let cost = helpers.priceFilter(scheduledWindow?.price);
          let isAvailable: boolean = scheduledWindow ? true : false;
          let deliver_win_temp = deliveryWindow_temp.startDateUtc
            ? deliveryWindow_temp
            : deliveryWindow;
          let isActive =
            JSON.stringify(scheduledWindow) ===
            JSON.stringify(deliver_win_temp);
          let daysHeader = days.map(
            (day) =>
              `${helpers.DAYS[day.getDay()].substring(0, 3)} ${day.getDate()}`
          );
          let day_actual: any = daysHeader[indice];

          let xcb: any = array_window_send.filter(
            (obj: any) => obj.dia === day_actual
          )[0];
          //para las ventas no disponibles en un horario pero si en otros
          if (xcb && !xcb.estado && isAvailable) {
            array_window_send[array_window_send.indexOf(xcb)] = {
              dia: day_actual,
              estado: isAvailable,
              costo: cost,
              activo: isActive,
              win: scheduledWindow,
            };
          }
          //para la ventana seleccionada al recargar
          if (xcb && isActive) {
            timeTempHour = scheduledWindow;
            array_window_send[array_window_send.indexOf(xcb)] = {
              dia: day_actual,
              estado: isAvailable,
              costo: cost,
              activo: isActive,
              win: scheduledWindow,
            };
          }

          let xca: any = array_window_send.filter(
            (x: any) => x.dia === day_actual
          )[0];

          if (!xca) {
            if (isActive) {
              positionWindow(scheduledWindow);
            }

            array_window_send.push({
              dia: day_actual,
              estado: isAvailable,
              costo: cost,
              activo: isActive,
              win: scheduledWindow,
            });
          }
        }
      ); // final de render SLOTS
      const inihora: any = scheduledRowItem[position]
        ? new Date(scheduledRowItem[position].startDateUtc)
        : "-";
      const endhora: any = scheduledRowItem[position]
        ? new Date(scheduledRowItem[position].endDateUtc)
        : "-";
      let hora_init_send: any = "No Disponible";
      let hora_fin_send: any = "";
      if (inihora !== "-") {
        hora_init_send = helpers.timeUTCFilter(inihora);
        hora_fin_send = helpers.timeUTCFilter(endhora);
        scheduledRows.push([
          <Week
            key={
              timeWindows[indexScheduledRow][0] +
              timeWindows[indexScheduledRow][1]
            }
            timeWindow={`${hora_init_send} - ${hora_fin_send} hrs`}
            win={scheduledRowItem[position]}
          >
            {renderSlots}
          </Week>,
        ]);
      }
    });
    setScheduledRow(scheduledRows);
    setWindows(array_window_send);
    if (!array_window_send.filter((x: any) => x.activo)[0]) {
      array_window_send.filter((x: any) => x.estado)[0].activo = true;
      positionWindow(array_window_send.filter((x: any) => x.estado)[0].win);
    }
  };

  const classes = useStyles();
  const formattedDate = `${helpers.capitalize(
    helpers.getMonthNameFilter(firstDate)
  )} ${firstDate.getFullYear()}`;
  useEffect(() => {
    init(schedule);
    if (timeTempHour.endDateUtc) {
      handleClick(timeTempHour);
    }
  }, []);
  const handleChange = async (event: any) => {
    const valor_send = JSON.parse(event.target.value);

    updateDeliveryWindowState(valor_send);
    await CheckOut.updateDeliveryWindow({
      ...valor_send,
    })
      .then(async (response: any) => {
        if (response.failed.length === 0) {
          return response;
        } else {
          setIsOpenDeleteDialog(true);
        }
      })
      .then((_response) => {
        setisLoading(false);
      })
      .catch((_error) => {
        setIsOpenError(true);
      });
  };

  return (
    <>
      <div className="horizontal-line" />
      <p className="horario">Fecha - {formattedDate}</p>
      <div id="wrapper">
        <div id="container">
          {windows.map((x: any, y: any) => (
            <SlotMobile
              key={y}
              daysHeader={x.dia}
              cost={isDelivery ? x.costo : "Gratis"}
              isAvailable={x.estado}
              isActive={x.activo}
              isLoading={isLoading}
              handleClick={() => {
                handleClick(x.win);
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <p className="horario">Horario</p>
        <select
          className="select-list text-select"
          id="selectHour"
          onChange={handleChange}
        >
          {scheduledRow.map((x: any, y: any) => {
            return (
              <option key={y} value={JSON.stringify(x[0].props.win)}>
                {x[0].props.timeWindow}
              </option>
            );
          })}
        </select>
      </div>

      {isOpenDeleteDialog && (
        <AlertDialogModal isOpen={isOpenDeleteDialog} handleClose={handleClose}>
          <HighlightOffIcon className={classes.root}></HighlightOffIcon>
          <p
            style={{
              textAlign: "justify",
              padding: "2px 65px",
              marginTop: "-28px",
              marginLeft: "-26px",
            }}
          >
            Lo sentimos, no pudimos guardar tu selección. Por favor, inténtalo
            nuevamente.
          </p>
        </AlertDialogModal>
      )}

      <AlertDialogRedirect
        isOpen={isOpenError}
        title="No tenemos cobertura de despacho"
        timeout={5}
        handleDialogAction={helpers.redirectToCartWithSL}
        redirectTo="carro"
      >
        <p style={{ textAlign: "justify" }}>
          "Lo sentimos, por ahora no tenemos cobertura de despacho para tu
          dirección. Por favor prueba con una distinta."
        </p>
      </AlertDialogRedirect>
    </>
  );
};
