import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import CheckOut from "../scenes/CheckOut";
import Notfound from '../scenes/NotFound';


export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={CheckOut} />
        <Route component={Notfound} />
      </Switch>
    </BrowserRouter>
  );
}
