import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";
import { activeToast } from "../Toast/Toast";
import OneclickCard from "./OneclickCard";
import analytics from "../../../../utils/analytics";
import CheckOut from "../../services/CheckOut.service";
import { OrderformContext } from "../../contexts/orderform.provider";
import "./oneclick_list.styles.scss";

const OneClickList = (props: any) => {
  const [loadingCard, setLoadingCard] = useState<boolean>(false)
  const {
    updateOrderFormData,
    setShippingData,
    setTotalizer,
    setCardOneClick,
    cardsOneClick,
    isSelectedCardId,
    setSelectedCard,
    orderform,
    updateOrderform,
    orderform: {
      paymentData,
      middlewarecard
    }
  } = useContext(OrderformContext);
  const isMobile = useWindowSize().width <= 711;
  const location = useLocation();
  const history = useHistory();

  const getCards = async (lastCard?: boolean) => {
    setLoadingCard(true);
    const response = await CheckOut.getCards();
    if (response?.length === 0) return null;
    setCardOneClick(response || []);
    const { customData, payments, shippingData, totalizers } = await selectOneCardByDefault(response);
    const isCardSelected = customData?.customApps?.find(({ id }: { id: string }) => id === "middlewarecard");
    setSelectedCard(isCardSelected?.fields?.inscriptionId);
    updateOrderFormData({
      ...orderform,
      ...customData,
      shippingData: shippingData,
      totalizerData: totalizers,
      paymentData: {
        ...paymentData,
        cardsOneClick: lastCard ? [] : response,
        payments,
      },
    });
    const responseCheckout = await CheckOut.ApiCheckout()
    await updateOrderform(responseCheckout.data);
    setShippingData(shippingData ? shippingData : orderform?.shippingData);
    setLoadingCard(false);
  };


  interface PaymentData {
    payments: any;
  }

  interface Card {
    type: 'debit' | 'credit';
  }

  /**
    * Update payments if they are empty.
    *
    * @param {PaymentData} paymentData - The payment data.
    * @param {Card[]} arrayCards - The payment cards.
    * @returns {Promise<any>} - An object with the updated payments.
  */
  async function updatePaymentsIfEmpty(paymentData: PaymentData, arrayCards: Card[]): Promise<any> {
    const codePaymentRule: Record<Card['type'], number> = { 'debit': 201, 'credit': 202 };
    /**
      * @type {number}
      * @description The payment code to use.
    */
    const codePayment: number = codePaymentRule[arrayCards?.[0]?.type ?? 'credit'];
    /**
     * updated payments.
     * @type {PaymentData['payments']}
     */
    const {
      paymentData: { payments },
      totalizers,
      shippingData
    } = await CheckOut.updateSelectedPaymentType(codePayment);
    setShippingData(shippingData)
    if (totalizers) setTotalizer(totalizers)
    return {
      payments: payments ? payments : paymentData.payments,
      totalizers,
      shippingData,
    }
  }
  /**
    * Filters an arrayCards object with _id equal to middlewarecard?.inscriptionId and adds it to the beginning of a new arrayCards.
    * @param {Object[]} arrayCards - Array of objects with _id and inscriptionId properties.
    * @param {Object} middlewarecard - Object with property inscriptionId.
    * @param {Object} paymentData - Object with properties payments and totalizers.
    * @returns {Object[]} New array of objects with _id and inscriptionId properties.
  */
  const addSelectedCardToBeginning = (arrayCards: any[], middlewarecard: { inscriptionId?: string }, paymentData: { payments: any[], totalizers: any[] }): any[] => {
    const selectedCard = arrayCards.find((card: any) => card._id === (middlewarecard?.inscriptionId ?? '0'));
    const newArrayCards = selectedCard ? [selectedCard, ...arrayCards.filter((card: any) => card !== selectedCard)] : arrayCards;
    return newArrayCards;
  };

  const selectOneCardByDefault = async (arrayCards: any) => {
    let paymentsValues = paymentData?.payments
    let totalizersValues = []
    let shippingDataValues = orderform?.shippingData || {}
    if (orderform) {
      totalizersValues = orderform.totalizerData;
    }
    if (Array.isArray(arrayCards)) {
      const query = new URLSearchParams(location.search);
      const registrationStatus = query.get('registration');
      if (registrationStatus === 'ok') {
        const {
          payments,
          shippingData,
          totalizers
        } = await updatePaymentsIfEmpty(paymentData, arrayCards);
        if (orderform) {
          orderform.totalizerData = totalizers;
          shippingDataValues = shippingData;
        }
        paymentsValues = payments

        const dataPayment = {
          inscriptionType: arrayCards[0].type,
          inscriptionId: arrayCards[0]._id
        }
        const { customData } = await CheckOut.updateCustomData(dataPayment, 'inscriptioncard')
        // Remove the "registration" query parameter
        query.delete("registration");

        // Update URL without "registration" query parameter
        history.replace({
          search: query.toString(),
        });
        setLoadingCard(false);
        return {
          customData: customData,
          totalizers: totalizers,
          shippingData: shippingDataValues,
          payments: paymentsValues
        }
      } else {
        const hasCard = arrayCards.some((card: any) => card._id === (middlewarecard?.inscriptionId ?? '0'))
        const newArrayCards = addSelectedCardToBeginning(arrayCards, middlewarecard, paymentData)
        const { payments, totalizers } = await updatePaymentsIfEmpty(paymentData, newArrayCards);
        paymentsValues = payments
        totalizersValues = totalizers
        if (arrayCards.length > 0 && !hasCard) {
          const dataPayment = {
            inscriptionType: arrayCards[0].type,
            inscriptionId: arrayCards[0]._id
          }
          const { customData } = await CheckOut.updateCustomData(dataPayment, 'inscriptioncard')
          return {
            customData,
            shippingData: shippingDataValues,
            totalizers: totalizers,
            payments: paymentsValues
          }
        }
      }
    }
    return {
      customData: orderform?.customData ?? {},
      totalizers: totalizersValues,
      shippingData: shippingDataValues,
      payments: paymentsValues
    }
  }

  const cardsItems = cardsOneClick?.length &&
    cardsOneClick?.map((cardOneclick: any) => {
      return (
        <OneclickCard
          key={cardOneclick._id}
          cardOneclick={cardOneclick}
          getCards={getCards}
          setSelectedCardType={props.setSelectedCardType}
          setSelectingDate={props.setSelectingDate}
        />
      );
    });
  const query = new URLSearchParams(location.search);
  const registrationStatus = query.get('registration');

  useEffect(() => {
    if (isSelectedCardId == '') {
      getCards()
    } else {
      setLoadingCard(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderform.customData, isSelectedCardId])

  // All modals are removed when ailimar, or add valid cards or not
  useEffect(() => {

    if (registrationStatus === 'ok' && !props.flagMessage && cardsOneClick.length > 0) {
      const message = `La tarjeta terminada en ${cardsOneClick?.[0]?.cardNumber?.slice(-4)} ha sido guardada de forma segura.`
      analytics.interactiveEvent({ action: 'Mensaje tarjeta guardada de forma segura', label: 'Tarjeta guarda' })
      activeToast({ message, statusOk: true });
    }

    if (registrationStatus && registrationStatus !== 'ok' && !props.flagMessage) {
      const message = 'No fue posible guardar tu tarjeta, intenta nuevamente.'
      analytics.interactiveEvent({ action: 'Mensaje no fue posible guardar la tarjeta', label: 'No fue posible agregar la tarjeta' })
      activeToast({ message, statusOk: false });
    }
  }, [cardsOneClick, location.search, props.flagMessage, registrationStatus])

  if (cardsItems?.length > 0) {
    return (
      <div style={{ width: '100%' }}>
        <p>Selecciona la tarjeta con la que quieres pagar</p>
        <div className='oneclick-list-container'>
          <div className='oneclick-list'>
            {loadingCard ? '' : cardsItems}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }} onClick={() => props.handleOnClick()}>
      <div className="empty-card-message">
        {isMobile ? (
          <p className="webpay-plus" style={{ textAlign: "left" }}>
            No tienes tarjetas registradas. Agrega una nueva tarjeta para pagar
            más rápido en tus siguientes compras.
          </p>
        ) : (
          <p className="padding-text webpay-plus">
            No tienes tarjetas registradas. Agrega una nueva tarjeta para pagar
            más rápido en tus siguientes compras.
          </p>
        )}
      </div>
    </div>
  );
};

export default OneClickList;
