import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import ReactGA from "react-ga";
import CheckOut from "../../services/CheckOut.service";
import { OrderformContext } from "../../contexts/orderform.provider";
import helpers from "../../../../utils/helpers";
import analytics from "../../../../utils/analytics";
import CoButton from "../../../../components/Buttons/CoButton";
import WaitMessage from "../../components/Oneclick/WaitMessage";
import WithoutStock from "../../components/WithoutStock/WithoutStock";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createStyles,
  makeStyles,
  withStyles
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Toggle } from "../../../../components/Toogle";
import { TotalizerRow } from "./TotalizerRow";
import "./totalizer.styles.scss";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 400,
      marginBottom: 4,
    },
    input: {
      marginLeft: "-5px",
      flex: 1,
      fontSize: 16,
      boxShadow: "none",
      fontWeight: 400,
      color: "#666666 !important",
      fontFamily: '"Open Sans", sans-serif',
    },
    button: {
      width: 300,
      paddingTop: 15,
      paddingBottom: 15,
    },
    buttonOn: {
      width: 300,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: "#02498E",
      color: "#fff",
    },
    text: {
      color: "#02498E",
    },
    terms: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

export const BlueCheckbox = withStyles({
  root: {
    color: "#02498E",
    "&$checked": {
      color: "#02498E",
    },
    "&:hover": {
      color: "#02498E",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="primary" {...props} />);

ReactGA.plugin.require("ec");

const Totalizer = (props: any) => {
  const {
    updateOrderform,
    handleHiddenTransferMethod,
    updateValidSections,
    openModalTransferMethod,
    valueMethodTransfer,
    setOpenModalTransferMethod,
    cardsOneClick,
    isReadyToPay,
    setTotalizer,
    isLoadingTransfer,
    loading,
    setIsError,
    orderform: {
      corporateData,
      totalizerData,
      marketingData,
      paymentData,
      clientProfileData,
      items,
      shippingData,
    },
    updateErrorService,
    errorService,
  } = useContext(OrderformContext);
  const {
    isDeliveryWindowSelected = false,
    isLoginTemp,
    isNumberPhoneFull,
    isSelectingDate,
    selectedPaymentMethod,
  } = props;
  const classes = useStyles();

  const [modalPrivacyPolicies, setModalPrivacyPolicies] = useState(false);
  const [textPrivacyTerms, setTextPrivacyTerms] = useState("");
  const [termsAndConditios, setTermsAndConditions] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [isOpenWaitMessage, setIsOpenWaitMessage] = useState(false);
  const [openDialogWithoutStock, setOpenDialogWithoutStock] = useState(false);
  const [openWindowNot, setOpenWindowNot] = useState(false);
  const [flagPay, setFlagPay] = useState(false);
  const [flagWindow, setFlagWindow] = useState(false);
  const [flagInsufficient, setFlagInsufficient] = useState(false);
  const [cupon, setCupon] = useState("");
  const placeHolderCupon = "Ingresar cupón";

  // Usados para setear los mensajes de los cupones
  const [textCupon, setTextCupon] = useState<string>("");
  const [titleCupon, setTitleCupon] = useState<string>("");
  const [modalCupon, setModalCupon] = useState<boolean>(false);

  const [spinnerCupon, setSpinnerCupon] = useState(false);
  const [isLoadingCupon, setIsLoadingCupon] = useState(false);
  const [isOpenDeleteDialogCupon, setIsOpenDeleteDialogCupon] = useState(false);
  const [state, setState] = useState(true);
  const [shippingValueState, setShippingValueState] = useState(0);
  let el_coupon: any = document.querySelector(".MuiPaper-elevation1");
  if (el_coupon) {
    el_coupon.classList.remove("MuiPaper-elevation1");
  }

  const isMobile = useWindowSize().width <= 1024;
  let totalItems = 0;
  items.forEach((item: any) => {
    totalItems += item?.quantity || 0;
  });
  const arrayItems = totalizerData.filter((x: any) => x.id === "Items")[0];
  let getPaymentTotalAmount = 0;
  totalizerData.forEach((itemToPay: { id: string; value: number }) => {
    if (itemToPay.id === "Discounts") {
      getPaymentTotalAmount += 0
    } else if (itemToPay.id === "Shipping") {
      getPaymentTotalAmount += shippingValueState ?? 0;
    } else {
      getPaymentTotalAmount += itemToPay.value;
    }
  });
  const paymentTotalMount =
    getPaymentTotalAmount < 0 ? 0 : getPaymentTotalAmount;
  const arrayCoupons = totalizerData.filter(
    (x: any) => x.id === "DiscountsCoupon" && x.value !== 0
  )[0];
  const arrayClubAhorro = totalizerData.filter(
    (x: any) => x.id === "DiscountsClubAhorro" && x.value !== 0
  )[0];
  const arrayCollaborator = totalizerData.filter(
    (x: any) => x.id === "DiscountsCollaborator" && x.value !== 0
  )[0];
  const arrayOthers = totalizerData.filter(
    (x: any) => x.id === "DiscountsCatalog" && x.value !== 0
  )[0];
  const arrayTaxes = totalizerData.filter((x: any) => x.id === "Tax")[0];

  const handleCloseCupon = () => {
    setIsOpenDeleteDialogCupon(false);
  };

  const handleChange = () => {
    setState(!state);
  };

  const openModalPrivacyPolicies = () => {
    setModalPrivacyPolicies(true);
  };

  useEffect(() => {
    if (openModal || modalPrivacyPolicies) {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    } else {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    };
  }, [openModal, modalPrivacyPolicies]);

  useEffect(() => {
    const valueShippingData = totalizerData?.find(
      (x: any) => x.id === "Shipping"
    )?.value ?? 0
    if (shippingValueState === 0 || (valueShippingData > 0 && valueShippingData !== shippingValueState)) setShippingValueState(valueShippingData)
  }, [totalizerData]);

  const closeModalPrivacyPolicies = () => {
    setModalPrivacyPolicies(false);
  };

  const termsCondiciones = () => {
    setOpenModal(true);
  };

  const cerrarModal = () => {
    setOpenModal(false);
  };

  const closeModalCupon = () => {
    setModalCupon(false);
  };

  const verifyWindow = async (payload: any) => {
    let response = true;
    if (
      payload &&
      payload.exceptions?.length > 0 &&
      payload.exceptions?.find((exception: any) => exception.code === "0005")
    ) {
      setFlagWindow(true);
      setIsOpenWaitMessage(false);
      response = false;
    }

    return response;
  };

  const setGiftcardPayment = async (id: any, balance: any, cardNumber: any) => {
    const paymentGiftCardSend = {
      idCard: id,
      balance: balance,
      cardNumber: cardNumber,
      inscriptionId: id,
    };

    await CheckOut.giftCardPayment(paymentGiftCardSend).then(
      async (response: any) => {
        await updateOrderform(response.data);
      }
    );
  };
  const verifyPaymentGiftCard = async () => {
    let response = false;
    const tempValueGiftCard: any = helpers.getStorage("giftCardStorage") || "";

    const valueGiftCard =
      tempValueGiftCard.length > 10 ? JSON.parse(tempValueGiftCard) : undefined;

    if (valueGiftCard) {
      const sendService = {
        cardNumber: valueGiftCard.numberCard,
        cardPin: valueGiftCard.cardPin,
        rut: clientProfileData.document,
      };
      const temporal = await CheckOut.giftCardBalance(sendService)
        .then(async (response: any) => {
          if (response.status === 201) {
            if (!helpers.getStorage("giftCardStorage")) {
              const sendDataStorage = {
                numberCard: valueGiftCard.numberCard,
                balance: response.data.balance,
                id: response.data.id,
                inscriptionId: response.data.inscriptionId,
                cardPin: valueGiftCard.cardPin,
              };
              helpers.setStorage(
                "giftCardStorage",
                JSON.stringify(sendDataStorage),
                null
              );
            }
            setGiftcardPayment(
              response.data.id,
              response.data.balance,
              valueGiftCard.numberCard
            );

            if (parseInt(response.data.balance) >= getPaymentTotalAmount) {
              return true;
            } else {
              return false;
            }
          } else {
            updateErrorService({
              ...errorService,
              openErrorService: true,
              refreshPage: false,
              btnText: "Ok",
            });
            return false;
          }
        })
        .catch((_error) => {
          updateErrorService({
            ...errorService,
            openErrorService: true,
            refreshPage: false,
          });
        });

      response = temporal || false;
    } else {
      response = false;
    }

    return response;
  };

  const setter = async () => {
    try {
      const value = await helpers.addCard();
      setIsOpenWaitMessage(value);
      analytics.interactiveEvent({
        action: "Click",
        label: "Botón agregar nueva tarjeta",
      });
    } catch (error) { }
  };
  const handleSubmit = async () => {
    setIsOpenWaitMessage(true);
    const updatedOrderform = await CheckOut.ApiCheckout();
    const cardSelected = updatedOrderform.paymentData.payments[0]
      ? updatedOrderform.paymentData?.paymentSystems.find(
        (element: any) =>
          element.id ===
          parseInt(updatedOrderform.paymentData.payments[0].paymentSystem)
      )
      : null;
    setSelectedCard(cardSelected);
    const typeCard = updatedOrderform?.customData?.customApps.find(
      (element: any) => element.id === "middlewarecard"
    ) || null

    if (cardSelected && updatedOrderform) {
      analytics.step4CheckoutMark(updatedOrderform, typeCard, cardSelected);
      analytics.addPaymentInfo({
        orderForm: updatedOrderform,
        paymentType: helpers.setPaymentType(cardSelected?.id?.toString()),
        typeCard: helpers.setCardType(cardSelected?.id?.toString()),
      });
    }
    setFlagPay(true);
    const verifyWindowFlag = await verifyWindow(updatedOrderform);
    if (verifyWindowFlag) {
      //Validar sin stock
      if (
        !(
          updatedOrderform.exceptions?.length > 0 &&
          updatedOrderform.exceptions?.find(
            (exception: any) => exception.code === "0001"
          )
        )
      ) {
        let giftCardVariant = paymentData?.giftCards
          ? paymentData.giftCards.filter((x: any) => x.inUse === true)[0]
            ? paymentData.giftCards.filter((x: any) => x.inUse === true)[0]
              .inUse
            : false
          : false;

        if (giftCardVariant) {
          const tempoverifyPaymentGiftCard = await verifyPaymentGiftCard();
          if (!tempoverifyPaymentGiftCard) {
            setFlagInsufficient(true);
            setIsOpenWaitMessage(false);
            return false;
          }
        }
        const findCorporateData = updatedOrderform?.customData?.customApps.find(
          (element: any) => element.id === "clientprofiledata"
        ) || null
        if (findCorporateData?.fields) {
          const responseUser = await CheckOut.getUser()
          if (!responseUser.corporateCity) {
            await CheckOut.updateBill({
              ...corporateData,
              "corporateCity": corporateData.corporateNeighborhood,
            })
          }
        }
        const dataTransaction = await CheckOut.generateTransaction({
          value: paymentTotalMount,
          deliveryWindow: updatedOrderform.shippingData.logisticsInfo[0].slas[0].deliveryWindow,
        });

        if (dataTransaction?.orderId) {
          if (giftCardVariant) helpers.removeStorage("giftCardStorage");
          const redirectUrl =
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? helpers.ALVI_ROUTES.PROD_ORDERP
              : helpers.ALVI_ROUTES.DESA_ORDERP;
          const orderIdRedirect = dataTransaction?.orderId.split("-")[0];
          window.location.href = `${redirectUrl}?og=${orderIdRedirect}`;
        } else {
          updateErrorService({ ...errorService, openErrorService: true });
        }
      }
    }
  };

  const handleSubmitSecond = () => {
    let redirectUrl =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? helpers.ALVI_ROUTES.PROD_CART
        : helpers.ALVI_ROUTES.DESA_CART;
    window.location.href = redirectUrl;
  };

  const sendCoupon = async (action: any = "") => {
    const dataSend: any = { coupon: cupon || "" };

    if (action === "delete") {
      dataSend.coupon = "";
    }
    setIsLoadingCupon(true);

    await CheckOut.generateCoupon(dataSend)
      .then(async (response: any) => {
        if (response && response.error) {
          //codigo promocionla invlaido y otros
          let messageResponse = response.error;

          if (messageResponse.search("inválido") > 0) {
            setModalCupon(true);
            setTextCupon(
              "Lo sentimos, el cupón que ingresaste no es válido. Por favor, intenta ingresando otro cupón."
            );
            setTitleCupon("El cupón ingresado no es válido");
          } else if (messageResponse.search("expirado") > 0) {
            setModalCupon(true);
            setTextCupon(
              "Lo sentimos, el cupón que ingresaste ha expirado. Por favor, intenta ingresando otro cupón."
            );
            setTitleCupon("El cupón ingresado ha expirado");
          } else {
            setModalCupon(true);
            setTextCupon(
              "Lo sentimos, tuvimos un error al cargar tu información. Por favor, intenta denuevo."
            );
            setTitleCupon("Problemas al cargar información");
          }
          setCupon("");
        } else {
          setTotalizer(response.totalizers);
          setCupon("");
        }
        setSpinnerCupon(false);
      })
      .catch((_response) => {
        setModalCupon(true);
      })
      .finally(() => {
        setIsLoadingCupon(false);
        setIsOpenDeleteDialogCupon(false);
      });
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (state && isNumberPhoneFull) {
      props.setSelectingDate(false);
    } else {
      props.setSelectingDate(true);
    }
    updateValidSections(helpers.SECTIONS.TOTALIZER, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const getHTMLPrivacyPolicities = async () => {
      const result = await fetch("/privacy_policies/index.html").then(
        (response) => response.text()
      );
      setTextPrivacyTerms(result.toString());
    };
    getHTMLPrivacyPolicities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getHTMLTermsAndCondition = async () => {
      const result = await fetch("/terms_conditions/index.html").then(
        (response) => response.text()
      );
      setTermsAndConditions(result.toString());
    };
    getHTMLTermsAndCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface CheckoutResponse {
    error: boolean;
    url?: string;
  }

  function retry(
    promiseFn: () => Promise<CheckoutResponse>,
    retries = 2,
    delay = 1000
  ): Promise<CheckoutResponse> {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        promiseFn()
          .then((response) => {
            if (response.error && retries === 0) {
              handleHiddenTransferMethod();
              setIsError({
                title: "Problemas al cargar",
                description:
                  "Lo sentimos, tenemos problemas al cargar la información de compra. Por favor, intenta más tarde o selecciona otro medio de pago.",
              });
              reject(new Error("Max retries exceeded"));
            } else if (response.url) {
              window.location.href = response.url;
              resolve(response);
            } else {
              retries--;
              setTimeout(attempt, delay);
            }
          })
          .catch((error) => {
            if (retries === 0) {
              reject(error);
            } else {
              retries--;
              setTimeout(attempt, delay);
            }
          });
      };
      attempt();
    });
  }

  /**
   * Handle action transfer method
   *
   * @param {Object}  event :{ target:{ id:string; name:string; value:any; }; }
   * @returns {void}
   */
  const handlePayWithTransfer = () => {
    const tagError = () => {
      analytics.interactiveEvent({
        action: "error-checkout-mp",
        label: "problema carga pasarela pago",
        paymentType: "Etpay",
        cardType: "Transferencia",
      });
    };
    setOpenModalTransferMethod(!openModalTransferMethod);

    CheckOut.updateSelectedPaymentType(203)
      .then(async () => {
        const updatedOrderform = await CheckOut.ApiCheckout();
        const cardSelected = updatedOrderform.paymentData.payments[0]
          ? updatedOrderform.paymentData?.paymentSystems.find(
            (element: any) =>
              element.id ===
              parseInt(updatedOrderform.paymentData.payments[0].paymentSystem)
          )
          : null;
        setSelectedCard(cardSelected);
        const typeCard = "Transferencia";
        if (cardSelected && updatedOrderform) {
          analytics.step4CheckoutMark(updatedOrderform, typeCard, cardSelected);
          analytics.addPaymentInfo({
            orderForm: updatedOrderform,
            paymentType: helpers.setPaymentType(cardSelected?.id?.toString()),
            typeCard: helpers.setCardType(cardSelected?.id?.toString()),
          });
        }

        const currentDeliveryWindow =
          shippingData.logisticsInfo[0]?.slas[0]?.deliveryWindow;
        const existingIncomingDeliveryWindow =
          updatedOrderform.shippingData.logisticsInfo[0]?.slas[0]
            ?.deliveryWindow;
        const deliveryWindow = {
          value: updatedOrderform.value ?? paymentTotalMount,
          deliveryWindow: currentDeliveryWindow ?? existingIncomingDeliveryWindow,
        };

        retry(() => CheckOut.transaction(deliveryWindow)).catch((error) => {
          tagError()
          setIsError({
            title: "Problemas al cargar",
            description:
              "Lo sentimos, tenemos problemas al cargar la información de compra. Por favor, intenta más tarde o selecciona otro medio de pago.",
          });
        });
      })
      .catch((e) => {
        handleHiddenTransferMethod();
        tagError();
        setIsError({
          title: "Problemas al cargar",
          description:
            "Lo sentimos, tenemos problemas al cargar la información de compra. Por favor, intenta más tarde o selecciona otro medio de pago.",
        });
      });
  };

  const payBtn = useCallback(() => {
    let btnContent = { disabled: true, payText: "Pagar" };
    switch (selectedPaymentMethod.toLowerCase()) {
      case "webpay":
        btnContent = { disabled: false, payText: "Pagar" };
        break;
      case "oneclick":
        const emptyCards =
          (cardsOneClick.length === 0) ||
          paymentData?.payments[0]?.paymentSystem === "30" || !state
        btnContent = {
          disabled: isLoadingTransfer || loading,
          payText: emptyCards ? "Agregar nueva tarjeta" : "Pagar",
        };
        break;
      case "giftcard":
        const emptyGiftCards = paymentData.giftCards.length === 0;
        btnContent = { disabled: emptyGiftCards, payText: "Pagar" };
        break;
      case "etpay":
        btnContent = { disabled: isLoadingTransfer || state, payText: "Pagar" };
        break;
    }
    const disabledCondition =
      !isReadyToPay || isSelectingDate || btnContent.disabled || !shippingData.logisticsInfo[0].slas[0].deliveryWindow;
    const isDisabled =
      btnContent.disabled || isSelectingDate || !state || !isNumberPhoneFull;
    const disablePayWithTransfer =
      selectedPaymentMethod === "etpay" && valueMethodTransfer && state
        ? false
        : disabledCondition;

    const shouldDisablePayWithTransfer = selectedPaymentMethod === "etpay" ? disablePayWithTransfer : false;

    return (
      <CoButton
        isLoading={isSelectingDate}
        disabled={
          loading || isSelectingDate || btnContent.payText === "Agregar nueva tarjeta"
            ? isDisabled
            : shouldDisablePayWithTransfer
        }
        handleClick={() => {
          if (valueMethodTransfer && btnContent.payText === "Pagar") {
            return handlePayWithTransfer();
          }
          return btnContent.payText === "Pagar" ? handleSubmit() : setter();
        }}
      >
        {btnContent.payText}
      </CoButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentData,
    selectedPaymentMethod,
    isLoadingTransfer,
    loading,
    cardsOneClick,
    shippingData?.logisticsInfo,
    state,
    isReadyToPay,
    isSelectingDate,
  ]);

  let spinnerOpacity = spinnerCupon ? 0.6 : 1;
  let spinnerPointer: any = spinnerCupon ? "none" : "all";
  let cuponDisabled = cupon.length > 0 ? false : true;
  const shippingValue = shippingValueState ?? 0;
  let valueShipping = (shippingValue === 0 || null || '') ? "Gratis" : `$ ${helpers.priceFilter(shippingValue)}`;

  if (!isDeliveryWindowSelected) return null

  return (
    <div className="totalizer">
      <div className="totalizer-content">
        {spinnerCupon && (
          <div
            style={{
              position: "relative",
              opacity: spinnerOpacity,
              pointerEvents: spinnerPointer,
            }}
          >
            <CircularProgress
              className={classes.text}
              size={60}
              style={{
                position: "absolute",
                top: "80px",
                left: "50%",
                zIndex: 1,
              }}
            />
          </div>
        )}

        {isMobile && (
          <>
            {marketingData &&
              marketingData.coupon &&
              marketingData.coupon.length > 0 ? (
              <div className="cupon-container">
                <p>Cupón Activo</p>
                <li
                  className="busqueda__tag"
                  key={0}
                  style={{ marginTop: "10px" }}
                >
                  <div key={0}>
                    <span
                      className="cupon-logo"
                      style={{ paddingLeft: "20px" }}
                    ></span>
                    <label className="busqueda__input">
                      Cupón: {marketingData.coupon}
                    </label>
                    <span
                      className="cupon-delete"
                      onClick={() => setIsOpenDeleteDialogCupon(true)}
                    ></span>
                  </div>
                </li>
              </div>
            ) : (
              <div className="cupon-container">
                <p style={{ marginTop: "0px" }}>
                  ¿Tienes un cupón de descuento?
                </p>
                <Paper className={"papperCoupon"}>
                  <div className="cupon-logo" aria-label="menu"></div>
                  <InputBase
                    className={classes.input}
                    placeholder={placeHolderCupon}
                    value={cupon}
                    onChange={(e: any) =>
                      setCupon(e.target.value.toUpperCase())
                    }
                  />
                  <CoButton
                    className={"button_cupon"}
                    disabled={cuponDisabled}
                    handleClick={() => sendCoupon()}
                    isLoading={isLoadingCupon}
                  >
                    Enviar
                  </CoButton>
                </Paper>
              </div>
            )}
            <hr className="totalizerhr" style={{ marginTop: "20px" }} />
          </>
        )}

        <div className="summary">
          <div className="title-totalizer">
            Resumen{" "}
            <span className="number_products">
              (
              {totalItems > 1
                ? `${totalItems} productos`
                : `${totalItems} producto`}
              )
            </span>
          </div>

          {arrayItems && (
            <TotalizerRow
              item="Subtotal"
              value={`$ ${helpers.priceFilter(Math.abs(arrayItems.value))}`}
            />
          )}

          {arrayTaxes?.value && (
            <TotalizerRow
              item="Impuestos"
              value={`$ ${helpers.priceFilter(arrayTaxes.value)}`}
            />
          )}

          {arrayClubAhorro?.value && (
            <TotalizerRow
              item="Cupones Club Alvi"
              value={`-$ ${helpers.priceFilter(
                Math.abs(arrayClubAhorro.value)
              )}`}
            />
          )}

          {arrayCollaborator?.value && (
            <TotalizerRow
              item="Descuento Colaborador"
              value={`-$ ${helpers.priceFilter(
                Math.abs(arrayCollaborator.value)
              )}`}
            />
          )}

          {arrayCoupons?.value && (
            <TotalizerRow
              item={`Cupón ${marketingData && marketingData.coupon
                ? marketingData.coupon
                : ""
                }`}
              value={`-$ ${helpers.priceFilter(Math.abs(arrayCoupons.value))}`}
            />
          )}

          {arrayOthers?.value && (
            <TotalizerRow
              item="Otros ahorros"
              value={`-$ ${helpers.priceFilter(Math.abs(arrayOthers.value))}`}
            />
          )}
          {Boolean(totalizerData) && (
            <TotalizerRow
              item="Despacho"
              value={valueShipping}
            />
          )}

          {paymentTotalMount === 0 && (
            <TotalizerRow isTotal item="Total" value={"$ 0"} />
          )}
          {paymentTotalMount > 0 && (
            <TotalizerRow
              isTotal
              item="Total"
              value={`$ ${paymentTotalMount !== 0
                ? helpers.priceFilter(paymentTotalMount)
                : "0"
                }`}
            />
          )}

          <hr className="totalizerhr" />

          {!isMobile && (
            <>
              {marketingData &&
                marketingData.coupon &&
                marketingData.coupon.length > 0 ? (
                <div>
                  <p>Cupón Activo</p>
                  <li
                    className="busqueda__tag"
                    key={0}
                    style={{ marginTop: "10px" }}
                  >
                    <div key={0}>
                      <span
                        className="cupon-logo"
                        style={{ paddingLeft: "20px" }}
                      ></span>

                      <label className="busqueda__input">
                        Cupón: {marketingData.coupon}
                      </label>

                      <span
                        className="cupon-delete"
                        onClick={() => setIsOpenDeleteDialogCupon(true)}
                      ></span>
                    </div>
                  </li>
                </div>
              ) : (
                <div>
                  <p>¿Tienes un cupón de descuento?</p>

                  <Paper className={"papperCoupon"}>
                    <div className="cupon-logo" aria-label="menu"></div>
                    <InputBase
                      className={classes.input}
                      placeholder={placeHolderCupon}
                      value={cupon}
                      onChange={(e: any) =>
                        setCupon(e.target.value.toUpperCase())
                      }
                    />
                    <CoButton
                      className={"button_cupon"}
                      disabled={cuponDisabled}
                      handleClick={() => sendCoupon()}
                      isLoading={isLoadingCupon}
                    >
                      Enviar
                    </CoButton>
                  </Paper>
                </div>
              )}
              <hr className="totalizerhr" style={{ marginTop: "20px" }} />
            </>
          )}

          {isMobile && (
            <div className="back-to-cart">
              <ShoppingCartOutlinedIcon></ShoppingCartOutlinedIcon>
              <div
                className="btn-look-as-a-link"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  paddingLeft: "5px",
                }}
                onClick={helpers.redirectToCart}
              >
                Volver al carro
              </div>
            </div>
          )}
        </div>

        <div className="">
          {!isLoginTemp && (
            <div className={`terms-and-pay ${classes.terms}`}>
              <div className="wrapper__terms-and-conditions">
                <Toggle state={state} onChange={handleChange} />
                <p className="terms-and-conditions">
                  Acepto los&nbsp;
                  <button
                    className="btn-look-as-a-link link_underline"
                    onClick={termsCondiciones}
                  >
                    términos y condiciones
                  </button>
                  &nbsp;y las&nbsp;
                  <button
                    className="btn-look-as-a-link link_underline"
                    onClick={openModalPrivacyPolicies}
                  >
                    políticas de privacidad
                  </button>
                </p>
              </div>
              <div className="btn-pay-wrapper">{payBtn()}</div>
            </div>
          )}
        </div>
      </div>
      <AlertDialog
        btnText="Entendido"
        disablePortal={true}
        handleClose={closeModalPrivacyPolicies}
        handleSubmit={closeModalPrivacyPolicies}
        isOpen={modalPrivacyPolicies}
        maxWidth="md"
        modalType="form"
        customTextTAC
        title="Políticas de privacidad"
      >
        <div
          className="temrinos_condiciones"
          dangerouslySetInnerHTML={{ __html: textPrivacyTerms }}
        ></div>
      </AlertDialog>

      <AlertDialog
        btnText="Entendido"
        disablePortal={true}
        handleClose={cerrarModal}
        handleSubmit={cerrarModal}
        isOpen={openModal}
        maxWidth="md"
        modalType="form"
        customTextTAC
        title="Términos y Condiciones"
      >
        <div
          className="temrinos_condiciones"
          dangerouslySetInnerHTML={{ __html: termsAndConditios }}
        ></div>
      </AlertDialog>

      <WaitMessage title="Espera un poco" isOpen={isOpenWaitMessage}>
        Nos estamos conectando de forma segura con tu medio de pago.
      </WaitMessage>

      <WithoutStock
        flagWindow={flagWindow}
        isOpen={openDialogWithoutStock} // Esto muestra productos que se cambiaron
        isOpenWin={openWindowNot} // Si no hay disponibilidad de entregar en esa ventana
        isPay={flagPay}
        selectedCard={selectedCard}
        setIsOpen={setOpenDialogWithoutStock}
        setIsOpenWin={setOpenWindowNot}
      />

      <AlertDialog
        btnText="Aceptar"
        handleClose={closeModalCupon}
        handleSubmit={closeModalCupon}
        isLoading={false}
        isOpen={modalCupon}
        modalType="alert"
        title={titleCupon}
      >
        {textCupon}
      </AlertDialog>

      <AlertDialog
        btnText="Eliminar"
        btnTextSecond="Mantener"
        isLoading={isLoadingCupon}
        isOpen={isOpenDeleteDialogCupon}
        modalType="alert"
        title="¿Estás seguro que quieres eliminar este cupón?"
        handleClose={handleCloseCupon}
        handleSubmit={() => {
          sendCoupon("delete");
        }}
        handleSubmitSecond={() => {
          setIsOpenDeleteDialogCupon(false);
        }}
      >
        {`Si lo eliminas, deberás volver a ingresarlo para que el descuento
          aplique nuevamente.`}
      </AlertDialog>

      <AlertDialog
        title={"No podemos continuar con tu compra"}
        isOpen={flagInsufficient}
        btnText={"Elegir otro medio de pago"}
        btnTextSecond={"Ir al carro"}
        handleSubmit={() => {
          setFlagInsufficient(false);
        }}
        handleSubmitSecond={handleSubmitSecond}
        isLoading={false}
      >
        Lo sentimos, el total de tu compra excede el saldo de tu gift card. Por
        favor selecciona otro medio de pago.
      </AlertDialog>
    </div>
  );
};

export default Totalizer;
