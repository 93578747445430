import React from "react";

export const Header = (props: any = {}) => {
  const { days } = props;
  return (
    <div className="week">
      <div className="slot header__week header__week__title">Horario</div>
      {days.map((day: any) => (
        <div className="slot header__week header__week__day" key={day}>
          {day}
        </div>
      ))}
    </div>
  );
};
