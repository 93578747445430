/* eslint-disable import/no-anonymous-default-export */
import CheckOut from "../scenes/CheckOut/services/CheckOut.service";

const API_DOMAIN = {
  DEV: "smu-labs.cl",
  PROD: "smu-service.cl",
};

const TIME_CHECKOUT = 3600;

const ENVIRONMENTS = {
  PROD: "production",
  DEV: "development",
  LOCAL: "local",
};

const API_ROUTES = {
  BFF: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://bff-alvi-web.smu-labs.cl",
    [ENVIRONMENTS.DEV]: "https://bff-alvi-web.smu-labs.cl",
    [ENVIRONMENTS.PROD]: "https://bff-alvi-web.alvi.cl",
  },
  BFFV2: {
    error: "error",
    [ENVIRONMENTS.LOCAL]: "https://bff-alvi-web.smu-labs.cl",
    [ENVIRONMENTS.DEV]: "https://bff-alvi-web.smu-labs.cl",
    [ENVIRONMENTS.PROD]: "https://bff-alvi-web.alvi.cl",
  },
};
const ALVI_ROUTES = {
  DESA_HOME: "http://fe-browse-alvi.smu-labs.cl",
  PROD_HOME: "https://alvi.cl/",
  DESA_CART: "https://fe-cart-alvi.smu-labs.cl/",
  PROD_CART: "https://carrito.alvi.cl/",
  DESA_CART_SL: "https://fe-cart-alvi.smu-labs.cl/?sl=true",
  PROD_CART_SL: "https://carrito.alvi.cl/?sl=true",
  DESA_ORDERP: "https://fe-orderplaced-alvi.smu-labs.cl",
  PROD_ORDERP: "https://orderplaced.alvi.cl",
};

const DAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const SECTIONS = {
  PROFILE: "profile",
  TOTALIZER: "totalizer",
  PAYMENT: "payment",
  SHIPPING: "shipping",
};

const getFullElementHeight = (elem: any, parent?: any) => {
  const root = parent === undefined ? document : parent;
  elem = typeof elem === "string" ? root.querySelector(elem) : elem;

  let styles = getComputedStyle(elem),
    margin =
      parseFloat(styles["marginTop"] || "") +
      parseFloat(styles["marginBottom"] || "");

  return Math.ceil(elem.offsetHeight + margin);
};
const isNull = (element: any) => element === null;
const isUndefined = (element: any) => element === undefined;
const isNullOrUndefined = (element: any) =>
  element === undefined || element === null;

  const capitalize = (str: string): string => {
    if (typeof str !== 'string') return '';
    
  
    const words = str.trim().split(/\s+/);
    const capitalizedWords = words.map((word) => {
      const firstChar = word.charAt(0).toUpperCase();
      const restOfWord = word.substring(1).toLowerCase();
      return firstChar + restOfWord;
    });
  
    return capitalizedWords.join(' ');
  };
  

const timeFilter = (date: Date) => {
  return `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}
  `;
};

const timeUTCFilter = (date: Date) => {
  if (!(date instanceof Date)) {
    throw new Error("El argumento debe ser una instancia de Date");
  }
  const hours = date.getHours();
  const minutes = date.getMinutes();
  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error("El argumento de fecha no es válido");
  }
  return `${("0" + date.getUTCHours()).slice(-2)}:${("0" + date.getUTCMinutes()).slice(-2)}`;
};

const getMonthNameFilter = (date: Date) => {
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  return monthNames[date.getUTCMonth()];
};

function priceFilter(n: any) {
  if (n === 0) return 0;
  if (!n) return null;
  var numParts: any = n.toString().slice(0, -2).split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numParts.join(".");
}

function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (let value of ca) {
    var c = value;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname: string, cvalue: string, exdays = 360, domain = "") {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  var stringDomain = "";
  if (domain) {
    stringDomain = ";domain=" + domain;
  }
  document.cookie =
    cname + "=" + cvalue + ";" + expires + stringDomain + ";path=/";
}

const getOrderformId = () => {
  return getCookie("co_orderform_id") || "ca6799d06fdd4ba1997e41ec0d1d2071";
};

function rutFilter(rut: any) {
  var sRut1 = rut; //contador de para saber cuando insertar el . o la -
  var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
  var sInvertido = ""; //Guarda el resultado final del rut como debe ser
  var sRut = "";
  for (var i = sRut1.length - 1; i >= 0; i--) {
    sInvertido += sRut1.charAt(i);
    if (i === sRut1.length - 1) sInvertido += "-";
    else if (nPos === 3) {
      sInvertido += ".";
      nPos = 0;
    }
    nPos++;
  }
  for (var j = sInvertido.length - 1; j >= 0; j--) {
    if (
      sInvertido.charAt(sInvertido.length - 1) !== "." ||
      j !== sInvertido.length - 1
    )
      sRut += sInvertido.charAt(j);
  }
  //Pasamos al campo el valor formateado
  return sRut.toUpperCase();
}

function rutUnfilter(rut: string) {

  // PASOS: https://validarutchile.cl /calcular-digito-verificador . php

  if (!rut) return "";
  return rut.replace(/\.|-/g, "");
}

function checkRut(rut: any) {
  // Divide el valor ingresado en dígito verificador y resto del RUT.
  if (!rut) return false;
  let valor = rut;
  let cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (cuerpo.length < 7) {
    return false;
  }

  // Calcular Dígito Verificador "Mét-odo del Módulo 11"
  let suma = 0;
  let multiplo = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= cuerpo.length; i++) {

    // Obtener su Producto con el Múltiplo Correspondiente PASO 3
    let index = multiplo * valor.charAt(cuerpo.length - i);

    // Sumar al Contador General PASO 4
    suma = suma + index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiplo < 7) {
      multiplo = multiplo + 1;
    } else {
      multiplo = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11 PASO 5
  let dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  // eslint-disable-next-line
  dv = dv == "K" ? 10 : dv;
  // eslint-disable-next-line
  dv = dv == 0 ? 11 : dv;

  // Validar que el Cuerpo coincide con su Dígito Verificador
  // eslint-disable-next-line
  if (dvEsperado != dv) {
    return false;
  } else {
    return true;
  }
}
const cardNumberFilter = (cardNumber: string) => {
  if (!cardNumber) return 0;
  cardNumber = cardNumber
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
  return cardNumber.replace(/X/g, "*");
};

const withoutAccent = (string: string) => {
  if (!string) return "";
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const redirectToHome = () => {
  let redirectUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ALVI_ROUTES.PROD_HOME
      : ALVI_ROUTES.DESA_HOME;
  window.location.href = redirectUrl;
};

const redirectToHomeTokenExpired = () => {
  let redirectUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ALVI_ROUTES.PROD_HOME
      : ALVI_ROUTES.DESA_HOME;
  window.location.href = redirectUrl + "?logoutSession=true";
};

const redirectToCart = () => {
  let redirectUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ALVI_ROUTES.PROD_CART
      : ALVI_ROUTES.DESA_CART;
  window.location.href = redirectUrl;
};

const redirectToCartWithSL = () => {
  let redirectUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ALVI_ROUTES.PROD_CART_SL
      : ALVI_ROUTES.DESA_CART_SL;
  window.location.href = redirectUrl;
};

const addCard = () => {
  const payload: any = {
    channel: "unimarc-web",
    inscriptionErrorUrl: `${window.location.origin}?registration=error`,
    inscriptionSuccessUrl: `${window.location.origin}?registration=ok`,
  };

  CheckOut.addCard(payload).then((response: any) => {
    const newWindow: any = window.open("", "_self");
    newWindow.document.write(response);
    newWindow.document.close();
    if (newWindow?.document?.f) {
      newWindow?.document?.f?.submit();
    }
  });
  return true;
};


const setStorage = (key: any, value: any, expires: any) => {

  if (expires === undefined || expires === null) {
    expires = (24 * 60 * 60);  // default: seconds for 1 day
  } else {
    expires = Math.abs(expires); //make sure it's positive
  }

  let now = Date.now();  //millisecs since epoch time, lets deal only with integer
  let schedule: any = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (_e) {
    return false;
  }
  return true;
}
const removeStorage = (name: any) => {
  try {
    localStorage.removeItem(name);
    localStorage.removeItem(name + '_expiresIn');
  } catch (e) {
    return false;
  }
  return true;
}

const getStorage = (key: any) => {

  let now = Date.now();  //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn: any = localStorage.getItem(key + '_expiresIn');
  if (expiresIn === undefined || expiresIn === null) { expiresIn = 0; }

  if (expiresIn < now) {// Expired
    removeStorage(key);
    return null;
  } else {
    try {
      return localStorage.getItem(key)
    } catch (_e) {
      return null;
    }
  }
}

const setCardType = (paymentId: string) => {
  switch (paymentId) {
    case '201':
      return 'Débito'
    case '202':
      return 'Crédito'
    case '203':
      return 'Transferencia'
    case '204':
      return  'Débito'
    case '205':
      return  'Crédito'
    case '16':
      return  'GiftCard'
    case '17':
      return  'Crédito'
  }
}

const setPaymentType = (paymentId: string) => {
  switch (paymentId) {
    case '201':
      return 'Oneclick'
    case '202':
      return 'Oneclick'
    case '203':
      return 'Etpay'
    case '204':
      return  'Klap'
    case '205':
      return  'Klap'
    case '16':
      return  'GiftCard'
    case '17':
      return  'WebPay Plus'
  }
}

export default {
  getFullElementHeight,
  isNull,
  isUndefined,
  isNullOrUndefined,
  capitalize,
  timeFilter,
  timeUTCFilter,
  getMonthNameFilter,
  priceFilter,
  getCookie,
  setCookie,
  getOrderformId,
  rutFilter,
  rutUnfilter,
  checkRut,
  cardNumberFilter,
  withoutAccent,
  redirectToHome,
  redirectToCart,
  redirectToCartWithSL,
  redirectToHomeTokenExpired,
  addCard,
  setCardType,
  setPaymentType,
  setStorage,
  getStorage,
  removeStorage,
  API_DOMAIN,
  ENVIRONMENTS,
  DAYS,
  SECTIONS,
  API_ROUTES,
  ALVI_ROUTES,
  TIME_CHECKOUT,
};
