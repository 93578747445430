import React from 'react';
import EditAction from '../../../../components/EditAction/EditAction'
import './invoice_preview.styles.scss';

const InvoicePreview = (props: any) => {
  const { setIsOpen, formCorporateData } = props;

  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <div className='invoice-preview-container'>
      <span className='b'> Datos de Facturación</span>
      <div className='invoice-preview-content'>
        <div className='invoice-preview-content__text'>
          {formCorporateData.corporateName} - RUT: {formCorporateData?.corporateDocument}
        </div>
        <EditAction text={'Cambiar'} handleClick={handleClick} />
      </div>
    </div>
  )
}

export default InvoicePreview;
