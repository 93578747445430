import React from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';

export type CoTextFieldProps = {
  autoFocus?: boolean;
  delValue?: any;
  endAdornment?: any;
  errorMessage?: string
  handleChange?: any;
  handleOnBlur?: any;
  maxLength?: any;
  handleOnFocus?: any;
  isValid?: boolean
  label: string;
  name: string;
  otherProps?: any;
  readOnly?: boolean;
  value: string;
} & TextFieldProps

const useStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: '#282828',
      fontSize: '16px'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #E5E5E5'
    },
    // remove arrows to input type number
    '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
}));

const CoTextField = ({
  autoFocus,
  endAdornment,
  errorMessage,
  handleChange,
  maxLength,
  handleOnBlur,
  handleOnFocus,
  label,
  name,
  readOnly,
  value,
  ...otherProps
}: CoTextFieldProps) => {
  const classes = useStyles();
  const adornmentEnd = endAdornment || <InputAdornment position="end"><span className="edit-icon-grey" /></InputAdornment>

  return (
    <TextField
      className={classes.root }
      error={errorMessage ? true : false}
      focused
      fullWidth={true}
      helperText={errorMessage}
      InputProps={{
        autoFocus: autoFocus,
        readOnly: readOnly,
        fullWidth: true,
        endAdornment: adornmentEnd
      }}
      inputProps={{ maxLength: maxLength }}
      label={label}
      name={name}
      onBlur={handleOnBlur ? (e) => handleOnBlur(e) : () => { return null }}
      onChange={(e) => handleChange(e)}
      onFocus={handleOnFocus}
      placeholder='Ingresa tu respuesta'
      value={value}
      variant="standard"
      {...otherProps}
    />
  );
};

export default CoTextField;
