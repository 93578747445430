import React, { useContext, useState } from 'react'
import AlertDialog from '../../../../components/AlertDialog/AlertDialog'
import helpers from '../../../../utils/helpers'
import { OrderformContext } from '../../contexts/orderform.provider'
import CheckOut from '../../services/CheckOut.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style.scss'

const GiftCard = (props: any) => {
  const { updateOrderform, updateValidSections } = useContext(OrderformContext)
  const [isLoading, setIsLoading] = useState(false)
  const [trashClicked, setTrashClicked] = useState(false)
  const tempValueGiftCard: any = helpers.getStorage('giftCardStorage') || ''

  const valueGiftCard = tempValueGiftCard.length > 10 ? JSON.parse(tempValueGiftCard) : undefined

  const trashIconStyle = {
    width: '15.45px',
    height: '15.45px',
    backgroundSize: '15.45px',
    margin: 'auto',
    paddingTop: '18.45px',
  }
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const selected = true

  const handleClickDelete = () => {
    setTrashClicked(true)
    setIsOpenDeleteDialog(true)
  }

  const handleClose = () => {
    setIsOpenDeleteDialog(false)
    setTrashClicked(false)
  }

  const handleSubmitDelete = () => {
    setIsLoading(true)
    setTrashClicked(true)

    CheckOut.deleteGiftCard({
      idCard: valueGiftCard.id,
      balance: valueGiftCard.balance,
      cardNumber: valueGiftCard.numberCard,
    })
      .then(async (response: any) => {
        helpers.removeStorage('giftCardStorage')
        props.setGiftCard({
          ...props.giftCard,
          passWordGiftCard: '',
          numberGiftCard: '',
        })
        updateOrderform(response.data)
        handleClose()
        setIsLoading(false)
        updateValidSections(helpers.SECTIONS.PAYMENT, false)
        return response
      })
      .catch((_error) => {
        handleClose()
        setIsLoading(false)
      })
  }

  return (
    <>
      <AlertDialog
        btnTextSecond='Cancelar'
        btnText='Eliminar'
        handleClose={handleClose}
        handleSubmit={() => {
          handleSubmitDelete()
        }}
        handleSubmitSecond={handleClose}
        isLoading={isLoading}
        isOpen={isOpenDeleteDialog}
        modalType='alert'
        title='¿Estás seguro que quieres eliminar tu Gift card?'
      >
        {`Si la eliminas, deberás volver a agregarla para poder utilizarla en tu compra.`}
      </AlertDialog>
      { }
      <div className={`oneclick-card ${selected ? 'active' : ''}`}>
        {(isLoading === true && trashClicked === false)
          && <CircularProgress className='loader-card' size={40} />
        }
        <div className='trash-icon__wrapper'>
          <div className='oneclick-card__card-Title'>Número de Gift card</div>
          <div
            className={`trash-icon__circle icon${selected ? '-active' : ''}`}
          >
            <div
              className='trash-icon'
              onClick={() => handleClickDelete()}
              style={trashIconStyle}
            ></div>
          </div>
        </div>
        <div className='oneclick-card__content'>
          <div className='oneclick-card__card-number'>
            {valueGiftCard.numberCard}
          </div>
          <div className='oneclick-card__card-TitleSaldo'>Saldo</div>
          <div className='oneclick-card__card-number'>
            ${helpers.priceFilter(valueGiftCard.balance)}
          </div>
        </div>
      </div>
      <div>
        <p>
          Al pagar con gift card, el saldo deberá cubrir su totalidad de tu
          compra.No podrás agregar o sustituir productos después de realizar tu
          pedido.
        </p>
      </div>
    </>
  )
}

export default GiftCard
