import React from "react";

const EditAction = (props: any) => {
    const { handleClick } = props;
    return (
      <>
      <span className="edit-action" onClick={() => handleClick()}>
        <span className="edit-icon" />
        <span className="edit-action__text">{props.text}</span>
      </span>
      </>
    );
  };

  export default EditAction;