import React  from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import "./co-button.styles.scss"

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    marginTop: '3px',
  },
}));

const CoButton = (props: any) => {
  const { type, children, disabled, handleClick, secondary, isLoading, className } = props;  
  const classes = useStyles();

  if(disabled) {
    return (
    <button className={`btn disabled ${className || ''}`} disabled>
      {children}
    </button>
    )
  } else {
    return (
      <button
        type={type}
        className={`btn enabled ${secondary ? "secondary" : ""} ${className || ''}`}
        onClick={isLoading ? () => {return null} : () => handleClick()}
      >
        {isLoading ? (
          <CircularProgress className={classes.root} size={20} />
        ) : (
          children
        )}
      </button>
    );
  }
};
export default CoButton;
