import React, { useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import "./WindowEditorMobile.styles.scss";

const useStyles = makeStyles(() => ({
  root: {
    color: '#02498E'
  },
}));

interface SlotMobileProps {
  cost: number | string;
  isAvailable: boolean;
  isActive?: boolean;
  handleClick: () => void;
  daysHeader: string;
  isLoading?: boolean;
}

const SlotMobile: React.FC<SlotMobileProps> = (props) => {
  const { cost, isAvailable, isActive = true, handleClick, daysHeader, isLoading } = props;
  const classes = useStyles();

  const [loadingTemp, setLoadingTemp] = useState(false);

  let filteredCost = `${daysHeader} Gratis`;
  if (cost !== 0) filteredCost = `${daysHeader} ${parseInt(cost as string) ? `$${cost}` : cost}`;
  let contentSlot: React.ReactNode;

  if (!isAvailable) {
    contentSlot = <div className="unavailable-line" />;
  } else if (isLoading && isActive) {
    contentSlot = <CircularProgress className={classes.root} size={20} />;
  } else if (isLoading && !isActive) {
    contentSlot = filteredCost;
  } else {
    contentSlot = filteredCost;
  }

  let classActive = "";
  if (isActive) {
    classActive = "active";
  }

  let classAvailable = "";
  if (!isAvailable) {
    classAvailable = "unavailable";
  }

  const handleSubmit = (): void => {
    handleClick();
    setLoadingTemp(true);
    setTimeout(() => {
      setLoadingTemp(false);
    }, 2000);
  };

  return (
    loadingTemp ? (
      <div className={`item text-mobile cost ${classActive} ${classAvailable}`}>
        {contentSlot}
      </div>
    ) : (
      <div
        onClick={() => isAvailable && handleSubmit()}
        className={`item text-mobile cost ${classActive} ${classAvailable}`}
      >
        {contentSlot}
      </div>
    )
  );
};

export default SlotMobile;
