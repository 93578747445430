import React, { Dispatch, SetStateAction } from "react";
import { BlueRadio } from "../Shipping/Shipping";
import { WindowEditorMobile } from "../Shipping/WindowEditorMobile";
import helpers from "../../../../utils/helpers";
import EditAction from "../../../../components/EditAction/EditAction";
import WhoReceives from "../WhoReceives/WhoReceives";
import { RenderDeliveryWindow } from "../Shipping/RenderDeliveryWindow";
import WindowEditorDesktop from "../Shipping/WindowEditorDesktop";

interface IStorePickUpRender {
  isDelivery: boolean;
  isMobile: boolean;
  isHiddenWindowEditor: boolean;
  orderform: any;
  toggleWindowEditor: () => void;
  setRedirectParam: Dispatch<SetStateAction<string>>;
  setIsOpenChangeAddressDialog: Dispatch<SetStateAction<boolean>>;
  setModalType: Dispatch<SetStateAction<string>>;
  selectedDeliveryWindowTag?: (tagInfo: any) => void;
  setShippingMethod: (method: string) => void;
}

export const StorePickUpRender = ({
    isDelivery,
    orderform,
    isHiddenWindowEditor,
    isMobile,
    setModalType,
    setRedirectParam,
    setIsOpenChangeAddressDialog,
    setShippingMethod,
    toggleWindowEditor,
    selectedDeliveryWindowTag,
    ...props
}: IStorePickUpRender) => {
  const selectedAddresses = orderform?.shippingData?.selectedAddresses || [];

  let storeData = {
    storeStreet: "",
    storeNumber: "",
    storeComplement: ""
  };

  if (selectedAddresses?.[0]) {
    const selectedAddress = selectedAddresses[0];

    storeData = {
      storeStreet: selectedAddress?.street ?? "",
      storeNumber: selectedAddress?.number ?? "",
      storeComplement: selectedAddress?.complement ?? ""
    };
  }

  const {
    storeComplement,
    storeNumber,
    storeStreet
  } = storeData;

  const neighborhood = orderform?.shippingData?.address?.neighborhood;
  const state = orderform?.shippingData?.address?.state;

  return (
    <div className={`option-container ${isDelivery ? "" : "active-border"}`}>
      <div className="shipping">
        <div className="type">
          <BlueRadio
            checked={!isDelivery}
            name="radio-button-despacho"
            onClick={() => {
              if (isDelivery) {
                setShippingMethod('pickup')
                setModalType("deliveryMethod");
                setRedirectParam("/?delivery-type=store");
                setIsOpenChangeAddressDialog(true);
              }
            }}
          />
          <span className="icon-shop" />
          <span className="type__text">Retiro en tienda</span>
        </div>
        {!isDelivery && (
          <>
            <div className="address-content">
              <div className="address">
                <p className="address__text">
                  <span className="b">
                    {" "}
                    Alvi {neighborhood}, {storeStreet}, {storeNumber},{" "}
                    {storeComplement}
                  </span>{" "}
                  {neighborhood}, {helpers.capitalize(state)}
                  {!isMobile && (
                    <span className="change_address">
                      <EditAction
                        text={"Cambiar tienda"}
                        handleClick={() => {
                          setModalType("store");
                          setRedirectParam("/?delivery-type=store");
                          setIsOpenChangeAddressDialog(true);
                          setShippingMethod('pickup')
                        }}
                      />
                    </span>
                  )}
                </p>

                {isMobile && (
                  <button
                    className="btn-change"
                    onClick={() => {
                      setModalType("store");
                      setRedirectParam("/?delivery-type=store");
                      setIsOpenChangeAddressDialog(true);
                    }}
                  >
                    Cambiar tienda
                  </button>
                )}
              </div>
              {/* This is the one that is rendered when checked out from the store */}
              <RenderDeliveryWindow
                handleClick={toggleWindowEditor}
                isHiddenWindowEditor={isHiddenWindowEditor}
                isMobile={isMobile}
              />
            </div>
            {isMobile && isHiddenWindowEditor ? (
              <WindowEditorMobile
                selectedDeliveryWindowTag={selectedDeliveryWindowTag}
                {...props}
              />
            ) : (
              isHiddenWindowEditor && (
                <WindowEditorDesktop
                  selectedDeliveryWindowTag={selectedDeliveryWindowTag}
                  {...props}
                />
              )
            )}
            <div className="horizontal-line" />
            <WhoReceives isStore={true} />
          </>
        )}
      </div>
    </div>
  );
};
