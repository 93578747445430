import React, {
  createContext,
  useState,
  useEffect
} from "react";
import { remote_config } from "../../../utils/firebase/firebase";
import CheckOut from "../services/CheckOut.service";

type ContextProps = {
  isReadyToPay: boolean;
  isLoading: boolean;
  updateLoading: any;
  updateOrderform: any;
  valueMethodTransfer: any;
  setMethodTransfer: any;
  setShippingData: any;
  openModalTransferMethod: any;
  isErrorTransaction: any;
  handleHiddenTransferMethod: any;
  isLoadingTransfer: any;
  setIsLoadingTransfer: any;
  loading: any;
  setLoading: any;
  setErrorTransaction: any;
  setOpenModalTransferMethod: any;
  isError: any;
  setIsError: any;
  setTotalizer: any;
  orderform: any;
  updateValidSections: any;
  updateGiftCard: any;
  updateErrorService: any;
  errorService: any;
  giftCard: any;
  isSelectedCardId: any;
  setCardOneClick: any;
  setSelectedCard: any;
  cardsOneClick: any;
  termsCondition: string;
  updateOrderFormData: any;
};

interface IErrorTransaction {
  title: string;
  description: string;
}
export const OrderformContext = createContext<Partial<ContextProps>>({});

const OrdeformProvider = (props: { children: React.ReactNode }) => {
  const [orderform, setOrderform] = useState({} as any);
  const [isReadyToPay, setIsReadyToPay] = useState(false);
  const [isValidSections, setIsValidSections] = useState<{ [key: string]: unknown }>({
    profile: null,
    shipping: true,
    payment: false,
    totalizer: null,
  });
  const [giftCard, setGiftCard] = useState({} as any);
  const [cardsOneClick, setCardOneClick] = useState([]);
  const [errorService, setErrorService] = useState({
    openErrorService: false,
    btnTexto: null,
    title: null,
    refreshPage: true,
  } as any);

  const [isLoading, setIsLoading] = useState(true);

  const updateValidSections = (sectionName: string, isValid: boolean) => {
    if (isValidSections[sectionName] !== isValid) {
      setIsValidSections((prevState) => {
        return {
          ...prevState,
          [sectionName]: isValid,
        };
      });
    }
  };

  const updateGiftCard = (giftCardUpdated: any) => {
    setGiftCard(giftCardUpdated);
  };

  const updateErrorService = (errorServiceUpdate: any) => {
    setErrorService(errorServiceUpdate);
  };

  const updateOrderform = async (updatedOrderform: any) => {
    let corporateData = null;
    let middlewarecard = null;
    let cardsOneclickToUpdate = null;

    if (!updatedOrderform?.isLoginTemp) {
      const findCustomApps = updatedOrderform?.customData?.customApps?.find(
        (customApp: any) => customApp.id === "clientprofiledata"
      )
      const findMiddleware = updatedOrderform?.customData?.customApps?.find(
        (customApp: any) => customApp.id === "middlewarecard"
      )

      if (findCustomApps) {
        corporateData = {
          ...JSON.parse(
            findCustomApps.fields?.corporateData
          ),
        };
      }

      if (findMiddleware) {
        middlewarecard = { ...findMiddleware.fields };
      }

      if (
        updatedOrderform.paymentData?.cardsOneclick === "" ||
        updatedOrderform.paymentData?.cardsOneclick === undefined
      ) {
        cardsOneclickToUpdate = [];
      } else {
        cardsOneclickToUpdate = updatedOrderform.paymentData.cardsOneclick || [];
      }
    }
    if (!updatedOrderform?.clientProfileData) {
      const userData = await CheckOut.getUser()
      if (userData.firstName) {
        updatedOrderform.clientProfileData = await CheckOut.updateClientProfile({
          "email": userData.email,
          "isCorporate": userData.isCorporate,
          "firstName": userData.firstName,
          "lastName": userData.lastName,
          "document": userData.document,
          "documentType": userData.documentType,
          "phone": userData.phone,
        })
      }
    }

    let methodsPayment = updatedOrderform?.methodsPayment || []
    const methodsDisposable = await remote_config.get('PAYMENT_OPTIONS')
    const formatPaymentAvailable = JSON.parse(methodsDisposable)
    if (formatPaymentAvailable.options[0].id === 'oneClick' && formatPaymentAvailable.options[0].enabled === false) {
      const getWebpayItem = updatedOrderform.methodsPayment.find((elem: any) => {
        return elem.id.toLowerCase() === 'webpay'.toLowerCase()
      })
      const data = await CheckOut.updateSelectedPaymentType(getWebpayItem.code[0])
      updatedOrderform.paymentData.payments = data.paymentData.payments
      methodsPayment = updatedOrderform.methodsPayment.filter((elem: any) => {
        return elem.id.toLowerCase() !== 'oneClick'.toLowerCase()
      })
    }
    setOrderform({
      customData: updatedOrderform.customData,
      orderFormId: updatedOrderform.orderFormId,
      hideTransfer: updatedOrderform.hideTransfer,
      termsCondition: updatedOrderform.termsCondition,
      clientProfileData: updatedOrderform.clientProfileData,
      totalizerData: updatedOrderform.totalizers,
      items: updatedOrderform.items,
      shippingData: updatedOrderform.shippingData,
      corporateData: {
        ...orderform?.corporateData,
        ...corporateData
      },
      marketingData: updatedOrderform.marketingData,
      value: updatedOrderform.value,
      paymentData: {
        cardsOneclick: cardsOneclickToUpdate,
        ...updatedOrderform.paymentData,
      },
      configurationFiles: {
        methodsPayment
      },
      middlewarecard,
      exceptions: updatedOrderform.exceptions,
      updateSelectedPaymentType: updatedOrderform.updateSelectedPaymentType,
      isLoginTemp: updatedOrderform.isLoginTemp || false,
    });
  };

  const setShippingData = (shippingData: any) => {
    setOrderform({ ...orderform, shippingData: shippingData });
  };
  const [isSelectedCardId, setSelectedCard] = useState('')

  const updateOrderFormData = (data: any) => {
    let newOrderForm = { ...orderform, ...data }
    let corporateData = newOrderForm?.corporateData

    let middlewarecard = newOrderForm?.middlewarecard
    const findCustomApps = newOrderForm?.customData?.customApps?.find(
      (customApp: any) => customApp.id === "clientprofiledata"
    )
    const findMiddleware = newOrderForm?.customData?.customApps?.find(
      (customApp: any) => customApp.id === "middlewarecard"
    )

    if (findCustomApps) {
      corporateData = { ...JSON.parse(findCustomApps.fields?.corporateData) };
    }

    if (findMiddleware) {
      middlewarecard = { ...findMiddleware.fields };
    }
    setOrderform({
      ...newOrderForm,
      middlewarecard,
      corporateData
    });
  };

  const setTotalizer = (totalizers: any) => {
    if (totalizers.length === 7) {
      setOrderform({
        ...orderform,
        marketingData: {
          "utmSource": null,
          "utmMedium": null,
          "utmCampaign": null,
          "utmipage": null,
          "utmiPart": null,
          "utmiCampaign": null,
          "coupon": totalizers[5].name?.slice(6),
          "marketingTags": [
            "nofactura"
          ]
        },
        totalizerData: totalizers,
      });
    } else {
      setOrderform({
        ...orderform,
        marketingData: {
          "utmSource": null,
          "utmMedium": null,
          "utmCampaign": null,
          "utmipage": null,
          "utmiPart": null,
          "utmiCampaign": null,
          "coupon": "",
          "marketingTags": [
            "nofactura"
          ]
        },
        totalizerData: totalizers,
      });
    }

  };

  // TODO: is it necessary? it is not using at index
  const updateLoading = (loadingStatus: boolean) => {
    if (isLoading !== loadingStatus) setIsLoading(loadingStatus);
  };

  //loading status on orderform
  useEffect(() => {
    if (
      orderform.clientProfileData &&
      orderform.totalizerData &&
      orderform.shippingData
    ) {
      setIsLoading(false);
    }
  }, [orderform]);

  //Set of ReadyToPay status, used in Pagar button
  useEffect(() => {
    const hasFalse = !Object.values(isValidSections).includes(false)
    if (isReadyToPay !== hasFalse) {
      setIsReadyToPay(hasFalse);
    }
  }, [isReadyToPay, isValidSections, orderform.paymentData]);

  const [valueMethodTransfer, setMethodTransfer] = useState(false)

  const [isError, setIsError] = useState<IErrorTransaction>({
    title: '',
    description: ''
  })
  const [openModalTransferMethod, setOpenModalTransferMethod] = useState(false)
  const [isErrorTransaction, setErrorTransaction] = useState(false)
  const [isLoadingTransfer, setIsLoadingTransfer] = useState(false)

  const [loading, setLoading] = useState<boolean>(false);


  const handleHiddenTransferMethod = () => {
    setOpenModalTransferMethod(false)
    setErrorTransaction(true)
  }
  return (
    <OrderformContext.Provider
      value={{
        cardsOneClick,
        errorService,
        giftCard,
        isError,
        isErrorTransaction,
        isLoading,
        isLoadingTransfer,
        isReadyToPay,
        isSelectedCardId,
        loading,
        openModalTransferMethod,
        orderform,
        valueMethodTransfer,
        handleHiddenTransferMethod,
        setCardOneClick,
        setErrorTransaction,
        setIsError,
        setIsLoadingTransfer,
        setLoading,
        setMethodTransfer,
        setOpenModalTransferMethod,
        setSelectedCard,
        setShippingData,
        setTotalizer,
        updateErrorService,
        updateGiftCard,
        updateLoading,
        updateOrderform,
        updateOrderFormData,
        updateValidSections
      }}
    >
      {props.children}
    </OrderformContext.Provider>
  );
};

export default OrdeformProvider;
