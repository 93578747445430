import React from "react";
import helpers from "../../../../../utils/helpers";
import Slot from "../Slot";
import { Week } from "./Week";
import "../WindowEditorMobile.styles.scss";

function areObjectsEqual(obj1: any, obj2: any) {
  if (obj1 == null || obj2 == null) {
    return false;
  }
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (const element of props1) {
    const propName = element;
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }
  return true;
}

interface ScheduledWindow {
    price?: number;
    lisPrice?: number;
    startDateUtc: string;
  };

  function TimeSlot({
    scheduledWindow,
    isDelivery,
    deliveryWindow,
    isLoading,
    handleClick,
  }: {
    scheduledWindow: ScheduledWindow;
    isDelivery: boolean;
    deliveryWindow: ScheduledWindow | null;
    isLoading: boolean;
    handleClick: (scheduledWindow: ScheduledWindow) => void;
  }) {
    const cost = isDelivery ? helpers.priceFilter(scheduledWindow?.price) : 0;
    const listPrice = scheduledWindow?.lisPrice;
    let isAvailable =
      typeof listPrice === "number" &&
      ((isDelivery && listPrice > 0) || (!isDelivery && listPrice === 0));
      const isActive = areObjectsEqual(scheduledWindow, deliveryWindow)
    const keyValue = scheduledWindow
      ? scheduledWindow.startDateUtc + scheduledWindow?.price?.toString()
      : "";
    return (
      <Slot
        key={keyValue}
        cost={cost}
        isAvailable={isAvailable}
        isActive={isActive}
        isLoading={isLoading}
        handleClick={() => handleClick(scheduledWindow)}
      />
    );
  }

  function TimeWindow({
    timeWindow,
    renderSlots,
  }: {
    timeWindow: [string, string];
    renderSlots: JSX.Element[];
  }) {
    const [startTime, endTime] = timeWindow;

    // create Date objects from strings
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    return (
      <Week key={`${timeWindow[0]}${timeWindow[1]}`} timeWindow={`${helpers.timeFilter(startDate)} - ${helpers.timeFilter(endDate)}`}>
        {renderSlots}
      </Week>
    );
  }

  interface Props {
    schedule: Array<Array<ScheduledWindow | undefined>>;
    timeWindows: [string, string][];
    isDelivery: boolean;
    deliveryWindow: ScheduledWindow | null;
    isLoading?: boolean;
    handleClick: (scheduledWindow: ScheduledWindow) => void;
  }

  export function TimeSlots({
    schedule,
    timeWindows,
    isDelivery,
    deliveryWindow,
    isLoading = false,
    handleClick,
  }: Props) {
    const scheduledRows = schedule.map((scheduledRow, indexScheduledRow) => {
      const renderSlots = (scheduledRow ?? []).map((scheduledWindow, indexScheduledWindow) => (
        <TimeSlot
          key={indexScheduledWindow}
          scheduledWindow={scheduledWindow!}
          isDelivery={isDelivery}
          deliveryWindow={deliveryWindow}
          isLoading={isLoading}
          handleClick={handleClick}
        />
      ));

      return (
        <React.Fragment key={indexScheduledRow}>
          <TimeWindow timeWindow={timeWindows[indexScheduledRow]} renderSlots={renderSlots} />
        </React.Fragment>
      );
    });

    return <>{scheduledRows}</>;
  }