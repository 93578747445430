import React from 'react';
import CoButton from '../../components/Buttons/CoButton';
import helpers from '../../utils/helpers';
import './styles.scss';

const NotFound = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div>
      <main className='checkOut'>
        <div className='header'>
          <div className='alvi-logo-notFound' />
        </div>
        <div className='wrapper_notFound'>
          <div className='title-section'>
            <>
              <span className='text-lose'>Lo sentimos...</span>
              <span className='text-lose2'>No pudimos encontrar lo que buscabas</span>
              <div className='booton-lose'>
                <CoButton
                  handleClick={helpers.redirectToHome}
                  isLoading={false}
                  className='btn-notFound'
                >
                  Volver al Home
                </CoButton>
              </div>
            </>
          <div className='not-found' />
          </div>
        </div>
        <div className='footer'>
          <p>© {currentYear} SMU | Todos los derechos reservados</p>
        </div>
      </main>
    </div>
  )
}

export default NotFound
