import React from 'react';
import './container.styles.scss'

const Container = (props: any) => {
  return (
    <div className='container'>
      <h3 className='container__title'>{props.title}</h3>
      <div className='horizontal-line' />
      <div>
        {props.children}
      </div>
    </div>
  )
}

export default Container;
