import { initializeApp } from "firebase/app";
import {
    getRemoteConfig,
    getValue,
    fetchAndActivate
} from "firebase/remote-config";
import { defaultConfig } from './defaultValues'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 15000;
remoteConfig.defaultConfig = defaultConfig
const remote_config = {}

remote_config.get = async (type) => {
    let data
    let error
    await fetchAndActivate(remoteConfig)
    .then(() => {
        // fetched configs successfully activate
        data = getValue(remoteConfig, type)
    })
    .catch(err => {
        // fetched configs were activate activate
        error = err
        console.error(err);
    });

    if(data) {
        return data._value;
    } else {
        return `{"error": "${error}"}`
    }
}
export {
    remote_config
}