import React from 'react';

interface WeekProps {
  key: string;
  timeWindow: string;
  win: any;
}

export const Week: React.FC<WeekProps> = ({ key, timeWindow, win, children }) => {
  return (
    <div key={key}>
      <div>{timeWindow}</div>
      <div>{win}</div>
      {children}
    </div>
  );
};
