import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: '#282828',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#282828',
        border:'1px solid'
      },
    },
    '& .MuiFormLabel-root': {
      fontFamily:'OPEN SANS'
    },
    '& .MuiFormHelperText-root': {
      fontSize:'0.9rem',
      fontFamily:'OPEN SANS'
    }
  },
}));

export const PhoneTextField = (props: any) => {
  const {
    handleChange, 
    handleOnBlur, 
    phone, 
    error
  } = props;
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        error = {error ? true : false}
        id="outlined-basic"
        variant="standard"
        size="small"
        type="text"
        value={phone}
        helperText={error}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleOnBlur(event)}
        InputProps={{
          fullWidth: true,
          startAdornment: <InputAdornment position="start">+56 9 </InputAdornment>,
          endAdornment: <InputAdornment position="end"><span className={ !error ? "edit-icon" : "close-icon" } /></InputAdornment>,
        }}
      />
    </div>
  );
}


