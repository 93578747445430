import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import { useWindowSize } from "../windowSize/use-window-size";
import "./alert-dialog-redirect.styles.scss";


const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialog-paper": {
      margin: "20px",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "10px",
    }
  },
}));

const errorIconStyle = {
  width: "20px",
  height: "20px",
  backgroundSize: "20px",
  marginRight: "10px",
};

const errorIconStyleMobile = {
  width: "30px",
  height: "20px",
  backgroundSize: "20px",
  marginRight: "10px",
};

const AlertDialogRedirect = (props: any) => {
  const { title, isOpen, handleDialogAction, redirectTo, timeout } = props;
  const classes = useStyles();
  const isMobile = useWindowSize().width <= 711;

  const [timer, setTimer] = useState(-1);

  const countdownTimer = () => {
    setTimeout(function () {
      setTimer((prevState) => {
        return prevState - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer < 0) {
      return;
    } else if (timer > 0) {
      countdownTimer();
    } else {
      handleDialogAction()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (isOpen === true) setTimer(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      maxWidth={isMobile ? "xl" : "sm"}
      className={classes.root}
    >
      <div className="dialog-alert-redirect">
        <div className="dialog-alert-redirect__content">
          {title && (
            <div className="dialog-alert-redirect__title-section">
              <div
                className="icon-error"
                style={isMobile ? errorIconStyleMobile : errorIconStyle}
              ></div>
              <div className="dialog-alert-redirect__title-text">{title}</div>
            </div>
          )}
          <div className="dialog-alert-redirect__body-text">
            {props.children}
          </div>
          <div className="dialog-alert-redirect__timeout">
            Ahora te redirigiremos al {redirectTo} en {timer} s
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialogRedirect;
