import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Profile from "./components/Profile/Profile";
import Shipping from "./components/Shipping/Shipping";
import Payment from "./components/Payment/Payment";
import Totalizer from "./components/Totalizer/Totalizer";
import ErrorService from "./components/ErrorService/ErrorService";
import { Toast } from "./components/Toast/Toast";
import { useWindowSize } from "../../components/windowSize/use-window-size";
import AlertDialogRedirect from "../../components/AlertDialogRedirect/AlertDialogRedirect";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import helpers from "../../utils/helpers";
import analytics from "../../utils/analytics";
import CheckOut from "./services/CheckOut.service";
import { OrderformContext } from "./contexts/orderform.provider";
import "./styles.scss";

ReactGA.plugin.require("ec");

const Inventory = () => {
  const {
    updateOrderform,
    isLoading,
    orderform: {
      totalizerData,
      isLoginTemp,
      shippingData,
      configurationFiles
    },
    errorService,
    updateErrorService
  } = useContext(OrderformContext);

  const location = useLocation();
  const [contador, setContador] = useState(0);
  const [openDialogAlertPayments, setOpenDialogAlertPayments] = useState({
    message: "",
    estado: false,
  });
  const [isNumberPhoneFull, setIsNumberPhoneFull] = useState<boolean>(false)
  const isDelivery = shippingData?.selectedAddresses[0]?.addressType === 'residential'
  const [tempRedirect, setTempRedirect] = useState(false);
  const [PaymentModalCodeError] = useState('');
  const [flagService, setFlagService] = useState(false);
  const userIdGTM = useRef('')
  const [gtmInit, setGtmInit] = useState(false);
  const [rulesObject, setRulerObjext] = useState({} as any);
  const [selectingDate, setSelectingDate] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [isDeliveryWindowSelected, setIsDeliveriWindowSelected] = useState(false);
  const currentYear = new Date().getFullYear()
  const isMobile = useWindowSize().width <= 1024;
  const isLoadingClass = isLoading ? 'is-loading' : ''

  const rulesEngine = async (payload: any) => {
    let response = true;
    if (
      payload &&
      (payload.message === "No token" ||
        payload.message === "No Autorizado" ||
        (payload.data && payload.data.totalizers.total_items < 1))
    ) {
      setRulerObjext({
        isOpen: true,
        title: "Ingreso Incorrecto",
        action: helpers.redirectToHome,
        redirectTo: "home",
        justify: "justify",
        message:
          " Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.",
      });

      response = false;
    } else if (
      payload &&
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0002"
      )
    ) {
      setRulerObjext({
        isOpen: true,
        title: "No hay capacidad de despacho",
        action: helpers.redirectToCartWithSL,
        redirectTo: "carro",
        justify: "",
        message:
          " Lo sentimos, por ahora no tenemos capacidad para atender tu pedido. Por favor, intenta con otra dirección.",
      });
      response = false;
    } else if (
      payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0003"
      ) &&
      !payload.data?.exceptions?.find(
        (exception: any) => exception.code === "0002"
      )
    ) {
      setRulerObjext({
        isOpen: true,
        title: "No tenemos cobertura de despacho",
        action: helpers.redirectToCartWithSL,
        redirectTo: "carro",
        justify: "justify",
        message: `Lo sentimos, por ahora no tenemos cobertura de despacho para tu dirección.
        Por favor prueba con una distinta.`,
      });
      response = false;
    }

    return response;
  };

  const closePaymentErrorModal = () => {
    analytics.acceptPaymentError('Cerrar', PaymentModalCodeError);
  }

  const acceptPaymentErrorModal = () => {
    analytics.acceptPaymentError('Aceptar', PaymentModalCodeError);

  }

  // USE EFFECT 1
  useEffect(() => {
    // Solamente acá Nos puede afectar el useEffect nunca se setea a false, Esto para que????
    // aparentemente no se necesita
    if (!window.localStorage.getItem("refrehCount")) {
      window.localStorage.setItem("refrehCount", "1");
    }

    helpers.getStorage("giftCardStorage");
  }, [location.search]);

  // USE EFFECT 2 Este esta haciendo muchos render. Se necesita para el primer render
  useEffect(() => {
    const processResponse = async (responseCheckout: any) => {
      const rulesResult = await rulesEngine(responseCheckout);
      if (rulesResult) {
        if (gtmInit === false) {
          userIdGTM.current = responseCheckout.userProfileId
          analytics.step2ChekcoutMark(responseCheckout);
          analytics.paginaVirtualCheckoutMark(responseCheckout);
          analytics.addShipingInfo(responseCheckout);
        }
        updateOrderform(responseCheckout);
        if (gtmInit === false) setGtmInit(true);
      }
    };

    const initMode = async () => {
      await CheckOut.ApiCheckout()
        .then(async (response: any) => {
          if (response.error) {
            setRulerObjext({
              isOpen: true,
              title: "Ingreso Incorrecto",
              redirectTo: "home",
              message: "Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.",
              action: helpers.redirectToHomeTokenExpired,
            });
          } else {
            if (!configurationFiles?.methodsPayment || configurationFiles?.methodsPayment?.length === 0) {
              const responseOrderForm = await CheckOut.ApiCheckout()
              processResponse(responseOrderForm);
            } else {
              processResponse(response);
            }
          }
        })
        .catch((_response) => {
          updateErrorService({ ...errorService, openErrorService: true });
          const countRefresh: any = window.localStorage.getItem("refrehCount");
          let newContRefresh: any = parseInt(countRefresh) + 1;
          if (newContRefresh > 3) {
            updateErrorService({ ...errorService, openErrorService: false });
            window.localStorage.setItem("refrehCount", "1");
            setTempRedirect(true);
          } else {
            window.localStorage.setItem("refrehCount", newContRefresh);
          }
        });
    }

    if (isLoading && !flagService) {
      setFlagService(true);
      initMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagService, isLoading]);

  useEffect(() => {
    if (contador > 4) {
      setRulerObjext({
        isOpen: true,
        title: "Ingreso Incorrecto",
        action: helpers.redirectToHome,
        redirectTo: "home",
        message:
          "Lo sentimos no tenemos los datos necesarios para poder continuar con esta acción.",
      });
    }
  }, [contador])

  useEffect(() => {
    const timeInterval = setTimeout(() => {
      if (
        !tempRedirect &&
        !errorService.openErrorService
      ) {
        setContador(contador + 1);
        if (contador === helpers.TIME_CHECKOUT) {
          setRulerObjext({
            isOpen: true,
            title: "Para proteger tus datos, te enviaremos el Inicio",
            action: helpers.redirectToHome,
            redirectTo: "home",
            justify: "",
            message: "Hemos notado que has permanecido en el checkout más tiempo del permitido. Por seguridad, te enviaremos al inicio del flujo.",
          });
        }
      }
    }, 10000);

    return () => {
      clearInterval(timeInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorService.openErrorService, tempRedirect])

  return (
    <div>
      <main className="checkOut" id="checkOut">
        <div className='header'>
          <a className={`unimarc-logo ${isLoadingClass}`} onClick={helpers.redirectToHome} />
          {!isLoading && (
            <p className="head-p">
              ¿Preguntas?. Llámanos de 9:00 a 20:00 hrs. al 600 600 0025{" "}
            </p>
          )}
        </div>
        <div className='wrapper'>
          {rulesObject.isOpen && (
            <AlertDialogRedirect
              isOpen={rulesObject.isOpen}
              title={rulesObject.title}
              timeout={5}
              handleDialogAction={rulesObject.action}
              redirectTo={rulesObject.redirectTo}
            >
              <p
                style={{
                  textAlign: rulesObject.justify,
                  whiteSpace: "pre-line",
                }}
              >
                {rulesObject.message}
              </p>
            </AlertDialogRedirect>
          )}

          <AlertDialogRedirect
            isOpen={totalizerData?.total_items <= 0}
            title={"No tienes productos en tu carro"}
            timeout={5}
            handleDialogAction={helpers.redirectToHome}
            redirectTo={"home"}
          >
            <p
              style={{
                textAlign: "justify",
                whiteSpace: "pre-line",
              }}
            >
              Lo sentimos no pudimos cargar la información de los productos de tu carro.
              Por favor intenta agregando algunos.
            </p>
          </AlertDialogRedirect>
          <ErrorService />
          <AlertDialogRedirect
            isOpen={tempRedirect}
            title="Problemas al cargar"
            timeout={5}
            handleDialogAction={helpers.redirectToHome}
            redirectTo="home"
          >
            <p
              style={{
                textAlign: "justify",
                whiteSpace: "pre-line",
                margin: 0,
              }}
            >
              Lo sentimos tenemos problemas al cargar la información de compra.
              Por favor intenta más tarde.
            </p>
          </AlertDialogRedirect>

          <AlertDialog
            btnText="Aceptar"
            btnTextSecond='Cancelar'
            handleClose={() => {
              setOpenDialogAlertPayments({ estado: false, message: "" });
              closePaymentErrorModal()
            }}
            handleSubmit={() => {
              setOpenDialogAlertPayments({ estado: false, message: "" });
              acceptPaymentErrorModal()
            }}
            handleSubmitSecond={() => {
              setOpenDialogAlertPayments({ estado: false, message: "" });
              closePaymentErrorModal()
            }}
            isOpen={openDialogAlertPayments.estado}
            modalType='alert'
            title="Error en la transacción"
          >
            <p style={{ textAlign: 'left' }}>{openDialogAlertPayments.message}</p>
          </AlertDialog>

          <div className="title-section">
            <h1 className="title-section__header">Mi compra</h1>
            {!isMobile && <h1 className="title-section__back-to-cart" onClick={helpers.redirectToCart}>Volver al carro</h1>}
            {!isMobile && <Toast width="350px" right="0" margin="2px 0" />}
          </div>

          {isLoading && (
            <>
              <div className="content">
                <div className="placeholder-item-profile" />
                <div className="placeholder-item-shipping" />
                <div className="placeholder-item-payment" />
                <div className="loader424-container" />
              </div>
            </>
          )}
          {!isLoading && (
            <>
              <div className="content">
                {isMobile && <Toast />}
                <Profile
                  isNumberPhoneFull={isNumberPhoneFull}
                  isSelectingDate={selectingDate}
                  setSelectingDate={setSelectingDate}
                  setIsNumberPhoneFull={setIsNumberPhoneFull}
                />
                <Shipping
                  onLoadDeliveryWindow={setIsDeliveriWindowSelected}
                  isDelivery={isDelivery}
                  isLoading={selectingDate}
                  setisLoading={setSelectingDate}
                />
                <Payment setSelectedPaymentMethod={setSelectedPaymentMethod} setSelectingDate={setSelectingDate} />
              </div>
              <Totalizer
                isDeliveryWindowSelected={isDeliveryWindowSelected}
                isLoginTemp={isLoginTemp}
                isNumberPhoneFull={isNumberPhoneFull}
                isSelectingDate={selectingDate}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectingDate={setSelectingDate}
                setIsNumberPhoneFull={setIsNumberPhoneFull}
              />
            </>
          )}

          {!isDeliveryWindowSelected &&
            <div className={`
            totalizer
            totalizer-rowLoader
            ${isLoading ? '' : 'totalizer-rowLoaderBottom'}
            `} title='loading'>
            </div>
          }
        </div>
        <div className='footer'>
          <p>© {currentYear} SMU | Todos los derechos reservados</p>
        </div>
      </main >
    </div >
  );
};

export default Inventory;
