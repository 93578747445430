import React, { useContext, useState } from "react";
import CheckOut from "../../../services/CheckOut.service";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AlertDialogModal from "../../../../../components/AlertDialogModal/AlertDialogModal";
import { Header } from "./Header";
import AlertDialogRedirect from "../../../../../components/AlertDialogRedirect/AlertDialogRedirect";
import helpers from "../../../../../utils/helpers";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OrderformContext } from "../../../contexts/orderform.provider";
import {
  getScheduleByTimeWindow,
  getUniqueDays,
  getUniqueTimewindows,
} from "../shipping.utils";
import { TimeSlots } from "./TimeSlots";
import "./WindowEditorDesktop.styles.scss";

interface IWindowEditorDesktopProps {
  isDelivery?: boolean;
  isLoading?: boolean;
  setisLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDeliveryWindowTag?: (tagInfo: any) => void;
}
const WindowEditorDesktop = ({
  isDelivery = false,
  isLoading,
  setisLoading = () => { return },
  selectedDeliveryWindowTag = () => {},
}: IWindowEditorDesktopProps) => {
  // STATE
  const [isOpenError, setIsOpenError] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const { orderform, setShippingData, setTotalizer } =
    useContext(OrderformContext);

  const {
    shippingData,
    shippingData: { logisticsInfo },
  } = orderform;

  const isPickup =
    shippingData?.logisticsInfo?.[0]?.selectedDeliveryChannel ===
    "pickup-in-point";
  const availableDeliveryWindows =
    logisticsInfo[0]?.slas[0]?.availableDeliveryWindows;

  const daysDeliveryWindows = availableDeliveryWindows?.filter((sla: any) => {
    if (isPickup) return sla?.windows?.[0]?.lisPrice === 0;
    return sla?.windows?.[0]?.lisPrice > 0;
  });

  let firstDate = new Date(
    daysDeliveryWindows?.[0]?.windows?.[0]?.startDateUtc
  );
  const deliveryWindow = logisticsInfo[0]?.slas[0]?.deliveryWindow;

  let timeWindows = getUniqueTimewindows(daysDeliveryWindows);
  let days = getUniqueDays(daysDeliveryWindows); // formato Jue 1, Vie 2, so on
  let schedule = getScheduleByTimeWindow(
    timeWindows,
    days,
    daysDeliveryWindows
  );
  const daysHeader = days.map(
    (day) => `${helpers.DAYS[day.getDay()].substring(0, 3)} ${day.getDate()}`
  );
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        color: "#02498E",
        marginLeft: "-30px",
        paddingTop: "30px",
      },
    })
  );
  // Build handle click function for Slot Component
  const updateDeliveryWindowState = (selectedDeliveryWindow: any) => {
    let updatedShippingData = { ...shippingData };
    updatedShippingData.logisticsInfo[0].slas[0].deliveryWindow =
      selectedDeliveryWindow;
    setShippingData(updatedShippingData);
  };
  const handleClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleClick = (selectedDeliveryWindow: any) => {
    // disable the add card button in the totalizer
    setisLoading(true);
    selectedDeliveryWindowTag(selectedDeliveryWindow);
    setisLoading(true);
    handleClose();
    const prevShippingData = { ...shippingData };
    updateDeliveryWindowState(selectedDeliveryWindow);
    CheckOut.updateDeliveryWindow({
      ...selectedDeliveryWindow,
    })
      .then(async (response: any) => {
        if (response?.totalizers) setTotalizer(response.totalizers);
        if (response.failed.length !== 0) {
          setIsOpenDeleteDialog(true);
        }
      })
      .then((_response) => {
        setisLoading(false);
      })
      .catch((_error) => {
        setIsOpenError(true);
        setShippingData({
          ...prevShippingData,
        });
      });
  };

  const classes = useStyles();

  return (
    <>
      <div className="horizontal-line" />
      <div className="window-editor">
        <p className="date b">
          {helpers.capitalize(helpers.getMonthNameFilter(firstDate))}{" "}
          {firstDate.getFullYear()}
        </p>
        <div className="calendar">
          <Header days={daysHeader} />
          <TimeSlots
            schedule={schedule || []}
            timeWindows={timeWindows}
            isDelivery={isDelivery}
            deliveryWindow={deliveryWindow}
            isLoading={isLoading}
            handleClick={handleClick}
          />
        </div>
      </div>
      {isOpenDeleteDialog && (
      <AlertDialogModal isOpen={isOpenDeleteDialog} handleClose={handleClose}>
        <HighlightOffIcon className={classes.root}></HighlightOffIcon>
        <p
          style={{
            textAlign: "justify",
            padding: "2px 65px",
            marginTop: "-28px",
            marginLeft: "-26px",
          }}
        >
          Lo sentimos, no pudimos guardar tu selección. Por favor, inténtalo
          nuevamente.
        </p>
      </AlertDialogModal>
    )}
    <AlertDialogRedirect
      isOpen={isOpenError}
      title="No tenemos cobertura de despacho"
      timeout={5}
      handleDialogAction={helpers.redirectToCartWithSL}
      redirectTo="carro"
    >
      <p style={{ textAlign: "justify" }}>
        "Lo sentimos, por ahora no tenemos cobertura de despacho para tu
        dirección. Por favor prueba con una distinta."
      </p>
    </AlertDialogRedirect>
    </>
  );
};

export default WindowEditorDesktop;
