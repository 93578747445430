import React from 'react';
import Routes from './routes/routes';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-141007824-8');
const tagManagerArgs = {
    gtmId: 'GTM-MZT85Q3'
}

TagManager.initialize(tagManagerArgs)

const App = () => (
	<Routes />
);

export default App;
