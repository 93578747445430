import React from "react";

export const Week = (props: any) => {
    const { timeWindow } = props;
    return (
      <div className="week">
        <div className="slot time-window">{timeWindow}</div>
        {props.children}
      </div>
    );
  };