import React from "react";

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import "./co-dialog.styles.scss"


const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      margin: '20px',
    },
  },
}));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      padding: "20px 18px 0px 16px;",
    },
    titleModal: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "27.24px",
      fontFamily: "Open Sans"
    },
    closeButton: {
      color: theme.palette.grey[900],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.titleModal}>
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});



const CoDialog = (props: any) => {
  const { title, isOpen, handleClose, isMobile } = props
  const classes = useStyles();
  let c: any = document.querySelector('.MuiPaper-rounded');
  if (c) {
    c.setAttribute('style', 'border-radius:10px');
  }

  return (
    <div className="dialog">
      {!isMobile ? (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
          maxWidth={props.maxWidth}
          className={props.className}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <span style={{ fontWeight: "bold" }}>{title}</span>
          </DialogTitle>
          <div>
            {props.children}
          </div>
        </Dialog>
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
          maxWidth="xl"
          className={classes.root}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
          {props.children}
        </Dialog>
      )}
    </div>
  );
};

export default CoDialog;