type IAvailableDeliveryWindows = IObjectsOfAvailableDeliveryWindows[]

export interface IObjectsOfAvailableDeliveryWindows {
  date: string;
  windows: IWindow[];
}

export interface IWindow {
  startDateUtc: string;
  endDateUtc: string;
  price?: number;
  lisPrice?: number;
  tax?: number;
}

//Dia Unicos
const getUniqueDays = (availableDeliveryWindows: IAvailableDeliveryWindows) => {

  let days: any[] = [];
  days = Array.from(
    new Set(
      availableDeliveryWindows.map((obj) => {
        const day = new Date(obj.windows[0].startDateUtc);
        return new Date(
          day.getFullYear(),
          day.getMonth(),
          day.getDate()
        ).getTime();
      })
    )
  );
  days = days.map((epochDay) => new Date(epochDay));
  return days;
};

const getUniqueTimewindows = (availableDeliveryWindows: IAvailableDeliveryWindows) => {

  const dates = availableDeliveryWindows.map(obj => {
    return obj.windows
  }).flat()

  // Ventanas horarias que se traen desde el api para que el cliente escoja hora de recogida o entrega del pedido
  let dupTimeWindows = dates.map(obj => {

    let timeWindow = [
      new Date(obj.startDateUtc),
      new Date(obj.endDateUtc),
    ];

    return [
      new Date(
        timeWindow[0].getUTCFullYear(), // año
        0,                              // index month
        0,                              // dia
        timeWindow[0].getUTCHours(),    // hora
        timeWindow[0].getUTCMinutes()   // minutos
      ).toString(),

      new Date(
        timeWindow[0].getUTCFullYear(),
        0,
        0,
        timeWindow[1].getUTCHours(),
        timeWindow[1].getUTCMinutes()
      ).toString(),

    ];
  }
  );

  let timeWindows: any = [];
  dupTimeWindows.forEach((window: any[]) => {
    let found = timeWindows.find(
      (el: any) => el[0] === window[0]
    );
    if (!found) timeWindows.push(window);
  });

  timeWindows.sort((prevTimeWindows: number, nextTimeWindows: number) => {
    let comparison = 0;
    if (prevTimeWindows > nextTimeWindows)
      comparison = 1
    else if (prevTimeWindows < nextTimeWindows)
      comparison = -1
    return comparison
  })

  timeWindows = timeWindows.map((window: any) => [
    new Date(window[0]),
    new Date(window[1]),
  ]);


  return timeWindows;
};


// Create array with available and unavailable timewindows.
const getScheduleByTimeWindow = (timeWindows: any[], days: any[], availableDeliveryWindows: IAvailableDeliveryWindows) => {

  //Cruce ventanas con días
  let schedule: (IWindow | undefined)[][] = [];

  const dates = availableDeliveryWindows.map(obj => {
    return obj.windows
  }).flat()

  timeWindows.forEach((timeWindowEl: any) => {
    let scheduleTimeWindow: (IWindow | undefined)[] = [];

    days.forEach((dayItemEl: { getMonth: () => number; getDate: () => number; }) => {
      let foundAvailableDeliveryWindow = dates.find(obj => {
        //compare time windows
        let availableTimeWindows = [
          new Date(obj.startDateUtc),
          new Date(obj.endDateUtc),
        ];

        return (
          timeWindowEl[0].getHours() === availableTimeWindows[0].getUTCHours() &&
          timeWindowEl[1].getHours() === availableTimeWindows[1].getUTCHours() &&
          dayItemEl.getMonth() === availableTimeWindows[0].getMonth() &&
          dayItemEl.getDate() === availableTimeWindows[0].getDate()
        );
      }
      );
      scheduleTimeWindow.push(foundAvailableDeliveryWindow);
    });
    schedule.push(scheduleTimeWindow);
  });

  return schedule;
}


export { getUniqueDays, getUniqueTimewindows, getScheduleByTimeWindow } 
