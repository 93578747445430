import React, {
  useContext,
  useEffect,
  useState
} from "react";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import Container from "../Container/Container";
import WhoReceives from "../WhoReceives/WhoReceives";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";
import EditAction from "../../../../components/EditAction/EditAction";
import { WindowEditorMobile } from "./WindowEditorMobile";
import CheckOut from "../../services/CheckOut.service";
import helpers from "../../../../utils/helpers";
import analytics from "../../../../utils/analytics";
import { OrderformContext } from "../../contexts/orderform.provider";
import { withStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { StorePickUpRender } from "../StorePickUpRender";
import { RenderDeliveryWindow } from "./RenderDeliveryWindow";
import WindowEditorDesktop from "./WindowEditorDesktop";
import "./shipping.styles.scss";

export const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: "#02498E",
    },
    '&:hover': {
      color: "#02498E",
    }
  },
  checked: {},
})((props: RadioProps) => <Radio color="primary" {...props} />);

const searchFirstWindowWithListPriceZero = (availableDeliveryWindows: any[], isPickup: boolean) => {
  if (!Boolean(availableDeliveryWindows?.length)) return {}
  return availableDeliveryWindows
    .flatMap((windowGroup: { windows: any; }) => windowGroup?.windows)
    .find((window: { lisPrice: number; }) => {
      if (isPickup) return window?.lisPrice === 0
      return window?.lisPrice > 0
    });
}



// componente 2 PRINCIPAL
const Shipping = (props: any) => {
  const { isDelivery, onLoadDeliveryWindow } = props
  const [deliveryWindowResponse, setDeliveryWindowResponse] = useState<any>(null);

  const {
    orderform,
    setTotalizer,
    setShippingData
  } = useContext(OrderformContext);
  const { shippingData } = orderform || {};
  const address = orderform?.shippingData?.address || {};
  const logisticsInfo = orderform?.shippingData?.logisticsInfo || {};
  const {
    street,
    number,
    neighborhood,
    state,
    complement
  } = address || {}
  const isMobile = useWindowSize().width <= 711;
  const [isHiddenWindowEditor, setIsHiddenWindowEditor] = useState(false);
  const [redirectParam, setRedirectParam] = useState('');
  const [modalType, setModalType] = useState('');
  const [shppingMethod, setShippingMethod] = useState('')

  const toggleWindowEditor = (): any => {
    setIsHiddenWindowEditor(!isHiddenWindowEditor);
    analytics.changeDirectionHour("Editar fecha y hora");
  };
  const [isOpenChangeAddressDialog, setIsOpenChangeAddressDialog] =
    useState(false);

  const handleCloseChangeAddressDialog = () => {
    setIsOpenChangeAddressDialog(false);
  };

  const handleClickChangeAddress = () => {
    setRedirectParam('/?delivery-type=delivery')
    setModalType('address')
    analytics.changeDirectionHour("Cambiar dirección");
    setIsOpenChangeAddressDialog(true);
  };

  const handleSubmitChangeAddress = () => {
    let redirectUrl =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? helpers.ALVI_ROUTES.PROD_CART
        : helpers.ALVI_ROUTES.DESA_CART;
    window.location.href = redirectUrl + redirectParam;
    analytics.clickChangeDirection(shppingMethod)
  };

  const selectedDeliveryWindowTag = (tagInfo: any) => {
    const startDate = new Date(tagInfo.startDateUtc);
    const endTime = helpers.timeUTCFilter(new Date(tagInfo.endDateUtc)).trim();
    const action = `${startDate.toLocaleDateString()} ${helpers
      .timeUTCFilter(startDate)
      .trim()} - ${endTime}`;
    analytics.interactiveEvent({ action, label: "Editar fecha y hora" });
  };

  const handleModal = () => {
    analytics.modalChangeDirection(orderform)
  }
  // componente 3 DENTRO DE SHIPPING
  const alertDialogContent = () => {
    let btnTextVal = ''
    let descriptionVal = ''

    switch (modalType) {
      case 'store':
        btnTextVal = 'la tienda'
        descriptionVal = 'Te redirigiremos al carro, para verificar si los productos que quieres comprar se encuentran disponibles en la nueva ubicación.'
        break;
      case 'deliveryMethod':
        btnTextVal = 'el tipo de entrega'
        descriptionVal = 'Te redirigiremos a tus direcciones, para verificar si los productos que quieres comprar se encuentran disponibles en la nueva ubicación.'
        break;
      case 'address':
        btnTextVal = 'dirección'
        descriptionVal = 'Es posible que los productos que quieres comprar no se encuentren disponibles en la nueva ubicación.'
        break;
    }

    return (
      <AlertDialog
        btnText={`Cambiar ${btnTextVal}`}
        btnTextSecond='Conservar mi dirección'
        handleClose={handleCloseChangeAddressDialog}
        handleModal={handleModal}
        handleSubmit={handleSubmitChangeAddress}
        handleSubmitSecond={handleCloseChangeAddressDialog}
        isLoading={false}
        isOpen={isOpenChangeAddressDialog}
        modalType='alert'
        title={`¿Estás seguro que quieres cambiar ${btnTextVal}?`}
      >
        {descriptionVal}
      </AlertDialog>
    )
  }

  useEffect(() => {
    async function validateDeliveryWindow() {
      if (logisticsInfo[0]?.slas[0]?.deliveryWindow !== null) return onLoadDeliveryWindow(true)
      const availableDeliveryWindows = logisticsInfo[0]?.slas[0]?.availableDeliveryWindows;
      const isPickup = shippingData?.logisticsInfo?.[0]?.selectedDeliveryChannel === "pickup-in-point"
      const deliveryWindow = searchFirstWindowWithListPriceZero(availableDeliveryWindows, isPickup)
      if (!deliveryWindow?.startDateUtc && deliveryWindowResponse) return onLoadDeliveryWindow(true)
      const responseWindow = await CheckOut.updateDeliveryWindow(deliveryWindow)
      setDeliveryWindowResponse(responseWindow); // update the response state
      if (Boolean(!deliveryWindowResponse && responseWindow?.shippingData)) {
        setShippingData({
          ...orderform?.shippingData,
          ...responseWindow?.shippingData
        });
        onLoadDeliveryWindow(true)
      }
    }
    validateDeliveryWindow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingData])

  useEffect(() => {
    if (deliveryWindowResponse) {
      if (deliveryWindowResponse?.totalizers) setTotalizer(deliveryWindowResponse.totalizers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryWindowResponse]);


  return (
    <Container title="Entrega: ">
      {/* TODO por ahora no hay retiro en tienda */}
      <StorePickUpRender
        isDelivery={isDelivery}
        isHiddenWindowEditor={isHiddenWindowEditor}
        isMobile={isMobile}
        orderform={orderform}
        selectedDeliveryWindowTag={selectedDeliveryWindowTag}
        setIsOpenChangeAddressDialog={setIsOpenChangeAddressDialog}
        setShippingMethod={setShippingMethod}
        setModalType={setModalType}
        setRedirectParam={setRedirectParam}
        toggleWindowEditor={toggleWindowEditor}
        {...props}
      />
      <div className={`option-container ${isDelivery ? 'active-border' : ''}`}>
        <div className="shipping">
          <div className="type">
            <BlueRadio
              checked={isDelivery}
              name="radio-button-despacho"
              onClick={() => {
                if (!isDelivery) {
                  setModalType('deliveryMethod')
                  setRedirectParam('/?delivery-type=delivery')
                  setShippingMethod('delivery')
                  setIsOpenChangeAddressDialog(true);
                  setShippingMethod('delivery')
                }
              }}
            />
            <span className="icon-truck" />
            <span className="type__text">
              Despacho a Domicilio
            </span>
          </div>
          {isDelivery && (
            <>
              <div className="address-content">

                <div className="address">
                  <p className="address__text">
                    <span className="b">
                      {" "}
                      {street}, {number}, {complement}
                    </span>{" "}
                    {neighborhood}, {helpers.capitalize(state)}

                    {!isMobile && (
                      <span className="change_address">
                        <EditAction
                          text={"Cambiar dirección"}
                          handleClick={()=>{
                            handleClickChangeAddress()
                            setShippingMethod('delivery')
                          }}
                        />
                      </span>
                    )}
                  </p>
                  {isMobile && (
                    <button className="btn-change" onClick={()=>{
                      handleClickChangeAddress()
                      setShippingMethod('delivery')
                      }} >
                      Cambiar dirección
                    </button>
                  )}
                </div>
                {/* This is the one that is rendered when checked out from the store */}
                <RenderDeliveryWindow
                  isHiddenWindowEditor={isHiddenWindowEditor}
                  isMobile={isMobile}
                  handleClick={toggleWindowEditor}
                  searchFirstWindowWithListPriceZero={searchFirstWindowWithListPriceZero}
                />
              </div>
              {(isMobile && isHiddenWindowEditor) && (
                <>
                  <WindowEditorMobile
                    selectedDeliveryWindowTag={selectedDeliveryWindowTag}
                    {...props}
                  />
                </>
                )}
              {(!isMobile && isHiddenWindowEditor) && (
                <WindowEditorDesktop
                  selectedDeliveryWindowTag={selectedDeliveryWindowTag}
                  {...props}
                />
              )}
              <div className="horizontal-line" />
              <WhoReceives />
            </>
          )}
        </div>
      </div>
      {alertDialogContent()}
    </Container>
  );
}; // componente 2 PRINCIPAL -> Cuando se hace envio

export default Shipping;
