import React from 'react';

const Bill = ({
  isOpen,
  handleToggle,
  disable
}: any) => {
  return (
    <div className='switch-bill'>
      <p className='text-bill'>
        Si necesitas <span>factura</span>, selecciona esta opción</p>
      <div className='switch'>
        <input
          className='switch-input'
          type='checkbox'
          role='switch'
          checked={isOpen}
          onChange={disable ? () => { return } : handleToggle}
        />
      </div>
    </div>
  );
};

export default Bill;